import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {TextSpaceonlyValidation, MobileNumberValidation,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername , validEmailRegex } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';

import Faq  from "react-faq-component";


const Faqsection = () => {
 

const [faqdata,setFaqdata]= useState([])
 

const styles = {
    
   
    
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};


 const GetFaq = async (  ) => {
   
      var postdata={ };

        const response = await fetch(Url+"getfaqs", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              var idata=data.data
              var faqdata=[]
              Object.keys(idata).forEach(function(key) {
    

                faqdata.push({ title:  idata[key].question ,content:  idata[key].answer })

              })


              

              setFaqdata({rows:faqdata})
          
          } else{
                 
          }

           
       })


 
  };


    useEffect(() => {
    GetFaq()
  }, []);

 
  return (
    <div id="app">

    <Header pageInfo="Aboutus" />

    <Navbar />


    <div className="main-section"> 

    <div className="main-container"> 


        <h1 className="site-head-main-title ">Frequently Asked Questions</h1>
    



         <Faq
                data={faqdata}
                styles={styles}
                config={config}
            />
 
 

    </div>


    </div>

 
    <Footer />


    </div>


 
  );
};
export default Faqsection;