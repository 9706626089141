 import React ,{useEffect,useState} from "react";
import {
    Link 
} from "react-router-dom";


import {Myprofileurl, Changepasswordurl, ReferUrl } from "../Siteurls.js"


const Sidebar = ( ) => {
  
 

   

  return (

 <div className="col-md-3 col-lg-3 col-xl-2 col-sm-3 col-12" >




          <div className="account-section-left bg-site-gradient  " >

          <ul className="sidebar-menu-list">

            <li className="side-menu-title"  ><a className="bt-bar" >Settings</a> </li>


             <li className="main-link" ><Link  to={Myprofileurl}  className="active"> <span className="main-link-icon"><i className="fa fa-user"></i> </span> Account </Link></li>


 <li className="main-link" ><Link  to={Changepasswordurl}   > <span className="main-link-icon"><i className="fa fa-user"></i> </span> Change Password </Link></li>


              <li className="main-link" ><Link><span className="main-link-icon"><i className="fa fa-user"></i> </span>  Billing </Link></li>


              <li className="main-link" ><Link><span className="main-link-icon"><i className="fa fa-user"></i> </span>  Channels</Link> </li>

              <li className="main-link" ><Link><span className="main-link-icon"><i className="fa fa-user"></i></span>  Organization</Link> </li>

              <li className="main-link" ><Link to={ReferUrl} > <span className="main-link-icon"><i className="fa fa-user"></i> </span> Refer a friend</Link> </li>



           </ul>



          </div>





         </div>




  );
};
export default Sidebar;