import React, {useEffect, useState } from "react";
  import { Notifyalert,baseUrl,Url,checkpermissionredirect} from './../../Api.js';


  import {    
   Allowenquiries_view
 } from './../../Permissions.js';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Enquiries = () => {

 
 


const [enquiriesList, setenquiriesList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(10);
 const [nPages, setnPages] = useState(1);
const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

const [checkedenquiryids,setcheckedenquiryids ] =useState('')
 const [emailtemplate,setEmailtemplate ] =useState('')
 const [emailtemplatesList,setemailtemplatesList ] =useState([])




 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetEnquiries()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetEnquiries()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetEnquiries(value)  
          
               
         
            
           

        }  



const SelectcheckIdbox = async(e)=>{

  var fields= document.getElementsByClassName('checkIdbox')
  var ids=[]
           Array.prototype.forEach.call(fields, function(el) {
                 if(e.target.checked == true){
                    el.setAttribute('checked', 'checked');
                    el.checked = true;
                    ids.push(el.value)
                 }else{
                    el.removeAttribute('checked' );
                   el.checked = false;
                }
              });

   await SetCheckedEnquiries()
}

const SetCheckedEnquiries = async()=>{


  var fields= document.getElementsByClassName('checkIdbox')

 var ids=[]
           Array.prototype.forEach.call(fields, function(el) {
                 if(el.checked == true){
                    
                    ids.push(el.value)
                 }else{
                    
                }
              });
 setcheckedenquiryids(ids.join(','))


}


const SetEmailTemplatevalue = async(e)=>{

    setEmailtemplate(e.target.value)
}





const Sendmail = async()=>{

    if(checkedenquiryids == ''){

        alert('Please choose atleast one enquiry')
    }else if (emailtemplate == ''){
         alert('Please choose email template')
    }else{

        var postdata= { enquiryid : checkedenquiryids, emailtemplate:emailtemplate }
            const response = await fetch(Url+"sendresponsetoenquiry", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            } ,
            body: JSON.stringify({postdata} ),

            }).then((response) => response.json())
            .then((data) => { 
            if (data.success) { 
                    Notifyalert('success',data.message)

            } else{

            }


            })



    }


}

  const GetEnquiries = async (value ) => {

     
    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage};

        const response = await fetch(Url+"getenquiries", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata} ),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setenquiriesList(data.data );
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };
  

    const GetEmailtemplates = async (value ) => {


        var postdata={'type':'promotional' , 'all':1};

        const response = await fetch(Url+"getemailtemplates", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata} ),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setemailtemplatesList(data.data );
       
         
          } else{
                 
          }

           
       })




    }
 

 


          useEffect(() => {
   GetEnquiries();
   GetEmailtemplates();
   checkpermissionredirect(Allowenquiries_view)
  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Enquiries List" />

         <Sidebar activePage="Enquiries" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Enquiries"/>

         
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Enquiries</span>




             </div>









            <div className="card-body">



   <div className="col-md-12 col-lg-12 row"> 

                    <div className="col-md-3">

                       <div className="col-md-12">
                            <label>Email Templates  </label>
                            </div>
                            <div className="col-md-12 form-group">

                            <select className="form-control" onChange={(e)=>SetEmailTemplatevalue(e)} >
                            <option value="">Choose</option>
                             {emailtemplatesList.map((emailtemplatesingle) => (
                                <option value={emailtemplatesingle._id}>{emailtemplatesingle.name}</option>


                           ))}

                             </select>
                           
                            </div> 


                    </div>
              
                    <div className="col-md-2">
                    <br/>
                       <a  onClick={Sendmail}  className="btn btn-success me-1 mb-1">Send Mail </a> 

                    </div>
                   

                </div>




                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>
                        <th> <input type="checkbox"  onChange={(e)=>SelectcheckIdbox(e)}  /></th>
                          <th>Name</th>

                            <th>Email</th>
                             <th>Mobile</th>
                              <th>Subject</th>
                            <th>Message</th>

                            <th>Date</th>
                    
                        </tr>
                    </thead>
                                          {  enquiriesList.length > 0 ? 

                    <tbody>
                       {enquiriesList.map((enquiry) => (
                        <tr>
                        <td> <input type="checkbox" onChange={SetCheckedEnquiries} className="checkIdbox" value={enquiry._id} /> </td> 
                                                    <td>{enquiry.name}</td>

                            <td>{enquiry.email}</td>
                             <td>{enquiry.mobile}</td>


                                                         <td>{enquiry.subject}</td>

                            <td>{enquiry.message}</td>

                            <td>  {  enquiry.createddatetime  }   </td>
                          
                          
                        </tr>
                        
                           ))}
                        
                    </tbody>:  <tbody><tr> <td  colspan="7" align="center" >No Records Found </td> </tr></tbody>}
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}

    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Enquiries;