import React, { useEffect,useState } from "react";
  import {SiteLogo,SiteFavicon,Url,CommonNotify , Notifyalert,TextSpaceonlyValidation, checkEmptyUndefined , requiredErrormessage,validEmailRegex} from './../Api.js';

 

 import {    
    Allowsettings_edit
} from './../Permissions.js';

const General = () => {

  
 

  const [companyname, setCompanyname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState({ preview: SiteLogo, data: '' })
  const [favicon, setFavicon] = useState({ preview: SiteFavicon, data: '' })


const [companynamereqerror, setcompanynamereqerror] = useState("");
const [addressreqerror, setaddressreqerror] = useState("");

const[cityreqerror,setcityreqerror]=useState("");
const[countryreqerror,setcountryreqerror]=useState("");
const[statereqerror,setstatereqerror]=useState("");
const[emailreqerror,setemailreqerror]=useState("");
const[phonereqerror,setphonereqerror]=useState("");
const[websitereqerror,setwebsitereqerror]=useState("");
const[logoreqerror,setlogoreqerror]=useState("");
const[faviconreqerror,setfaviconreqerror]=useState("");





const handleUserInput = (e) => {
      var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        case 'companyname':
              (TextSpaceonlyValidation(value) == true ) ?  setCompanyname(value) : setcompanynamereqerror('');
             break;

         case 'address':
            setAddress(value);
            setaddressreqerror('');
            break;

            case 'city':
            setCity(value);
            setcityreqerror('');
            break;

            case 'country':
            setCountry(value);
            setcountryreqerror('');
            break;

            case 'state':
            setState(value);
            setstatereqerror('');
            break;

            case 'phone':
            setPhone(value);
            setphonereqerror('');
            break;

            case 'website':
            setWebsite(value);
            setwebsitereqerror('');
            break;

                



        case 'email':
            setEmail(value);
            setemailreqerror('');
            break;
        

        default:
            break;      
    }
 }



 const Getsettings = async (e) => {
 
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setCompanyname(settingsdata.companyname );
             setAddress(settingsdata.address );
             setCity(settingsdata.city );
             setCountry(settingsdata.country );
             setState(settingsdata.state );
             setEmail(settingsdata.email );
             setPhone(settingsdata.phone );
             setWebsite(settingsdata.website );

            
         
          } else{
              //   
          }

           
       })

 

 } 




  const Updategeneralsettings = async (e) => {
 
 setcompanynamereqerror()
setaddressreqerror()

setcityreqerror()
setcountryreqerror()
setstatereqerror()
setemailreqerror()
setphonereqerror()
setwebsitereqerror()
setlogoreqerror()
setfaviconreqerror()

    var validationerror = 0;

        if(checkEmptyUndefined(companyname) == false){
        setcompanynamereqerror(requiredErrormessage)
        validationerror=1;
        }
        if(checkEmptyUndefined(address) == false){
        setaddressreqerror(requiredErrormessage)
        validationerror=1;
        }
        if(checkEmptyUndefined(city) == false){
        setcityreqerror (requiredErrormessage)
        validationerror=1;
        }
        if(checkEmptyUndefined(country) == false){
        setcountryreqerror(requiredErrormessage)
        validationerror=1;
        }
        if(checkEmptyUndefined(state) == false){
        setstatereqerror(requiredErrormessage)
        validationerror=1;
        }
        if(checkEmptyUndefined(email) == false){
        setemailreqerror(requiredErrormessage)
        validationerror=1;
        }
        if(checkEmptyUndefined(email) == true && validEmailRegex.test(email) == false){
        setemailreqerror("Invalid Email")
        validationerror=1; 
    }

        if(checkEmptyUndefined(phone) == false){
        setphonereqerror(requiredErrormessage)
        validationerror=1;
        }
        if(checkEmptyUndefined(website) == false){
        setwebsitereqerror(requiredErrormessage)
        validationerror=1;
        }






     if(validationerror == 0){ 

       let formData = new FormData()
      formData.append('logo', logo.data)
      formData.append('favicon', favicon.data)
      formData.append('companyname', companyname)

      formData.append('address', address)
      formData.append('city', city)
      formData.append('country', country)
      formData.append('state', state)
      formData.append('email', email)
      formData.append('phone', phone)
      formData.append('website', website)

      const response = await fetch(Url+"update-settings", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            Notifyalert('success', data.message)
          
          } else{
                 Notifyalert('error', data.message)

          }

           
       })
 
    }
  };



 const handleLogoChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setLogo(img)
  }
 const handleFaviconChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setFavicon(img)
  }

  



 useEffect(() => {
 Getsettings();
  }, []); 

   

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="companyname">Company Name</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="companyname" placeholder="Company Name" value={companyname} onChange={(e) => handleUserInput(e)}  />
                                    <p className="req-error-message">{companynamereqerror} </p>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="address">Address</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="address" placeholder="Address" value={address} onChange={(e) => handleUserInput(e)}  />
                                     <p className="req-error-message">{addressreqerror} </p>

                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">City</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="city" placeholder="City" value={city} onChange={(e) => handleUserInput(e)}  />
                                    <p className="req-error-message">{cityreqerror} </p>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">State</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="state" placeholder="State" value={state} onChange={(e) => handleUserInput(e)}  />
                                    <p className="req-error-message">{statereqerror} </p>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Country</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="country" placeholder="Country" value={country} onChange={(e) => handleUserInput(e)}  />
                                    <p className="req-error-message">{countryreqerror} </p>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Email</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="email" placeholder="Email" value={email} onChange={(e) => handleUserInput(e)}  />
                                  <p className="req-error-message">{emailreqerror} </p>

                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Phone</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="phone" placeholder="Phone" value={phone} onChange={(e) => handleUserInput(e)}  />
                                        <p className="req-error-message">{phonereqerror} </p>

                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Website</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="website" placeholder="Website" value={website} onChange={(e) => handleUserInput(e)}  />
                                       <p className="req-error-message">{websitereqerror} </p>

                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="logo">Logo</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input type="file"  id="logo" name="logo"   onChange={handleLogoChange}/>
                                    <br/>
                                    <br/>
                                    <img alt="logo" className="img-responsive w-100px"  src={logo.preview} />
                                </div>


                                <div className="col-md-4">
                                    <label htmlFor="favicon">Favicon</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input type="file" id="favicon" name="favicon" onChange={handleFaviconChange} />
                                    <br/>
                                    <br/>
                                    <img alt="favicon" className="img-responsive w-100px" src={favicon.preview} />
                                </div>

                                {(Allowsettings_edit == 1) ? 

                                 <div className="col-sm-12 d-flex justify-content-end">
                                  <a   className="btn btn-primary me-1 mb-1" onClick={Updategeneralsettings}>Submit</a>
                               </div> : '' }
 

                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default General;