import React, {    useState} from "react";
import { Link,   useLocation,    useNavigate} from "react-router-dom";
import {Sitename,validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirecthome,UrlSplitter,SetNotificationAfterName } from '../Frontapi.js'; 
  import Header from '../includes/Header';

 import Navbar from '../includes/Navbar';
 import Footer from '../includes/Footer';

import {Lang} from "../Languages.js"


const Resetpassword = () => {
 

   

        const token = UrlSplitter(2);




        const [notification, setnotification] = useState("");
        const [notificationtype, setnotificationtype] = useState("");

        const [password, setPassword] = useState("");

        const [confirm_password, setConfirmPassword] = useState("");

        const [formValid, setformValid] = useState(false);
        const [validationSubmit, setvalidationSubmit] = useState(false);
        const [btndisabled, setbtndisabled] = useState('btn-disabled');

        const [passwordreqerror, setpasswordreqerror] = useState("");
        const [confirmpasswordreqerror, setconfirmpasswordreqerror] = useState("");



    const handleUserInput = (e) => {
       setbtndisabled('') 
           setconfirmpasswordreqerror('')

             
             var name = e.target.name;
            var value = e.target.value;
            switch (name) {
            case 'password':
            setPassword(value);
            break;
            case 'confirm_password':
            setConfirmPassword(value);
            break;    

            }

            if(password == '' ||  confirm_password == ''){

                  setbtndisabled('btn-disabled') 
            }



 

     
   }

        const Updatepassword = async (e) => {
            e.preventDefault();

 var validationerror = 0;
 setpasswordreqerror('')
    setconfirmpasswordreqerror('')


 


             if(   password != confirm_password )  { 
                 validationerror = 1;
                 // setbtndisabled('btn-disabled') 
                  setconfirmpasswordreqerror('Password and Confirm Password Not Matched')

            }
            



      if(validationerror == 0){ 

            var postdata = {
                password: password,token:token
            };
           
                const response = await fetch(Url + "update-user-password", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success',data.message)
                            setPassword('');
                             setConfirmPassword('');
                             

                         } else {
                           Notifyalert('error',data.message)
                         }
                    })

                }
           
        };


        
      


  return (
 <div id="app">
   <Header pageInfo="Login" />
   <Navbar />
   <div className="main-section">
         <div className="login-container">

      <div className="card bg-site-gradient  "  >
            <div className="card-body p-5 text-center">
               <div className=" mb-5 ">
                  <h1 className="site-head-main-title ">{Lang('Reset Password')} </h1>

                      <div className="form-group">
                         <input  className="site-form-input login-input  mb-25 MustEnterField"  value={password}  type="password" name="password"   placeholder="Password"  
                           onChange={(e) => handleUserInput(e)}
                        required/>
                     </div>
                     <div className="form-group">
                         <input  className="site-form-input login-input  mb-25 MustEnterField"  value={confirm_password}  type="password" name="confirm_password"   placeholder="Confirm Password"  
                           onChange={(e) => handleUserInput(e)}
                        required/>
                        <p className="req-error-message">{confirmpasswordreqerror}</p>
                     </div>
                       <p className="forgot-password-inst"  > <Link to={frontUrl+'login'} >Go back to Login</Link> </p>             
                      <div className="clearfix">
                         <button className={ "amigo-btn bg-prim "+btndisabled}  onClick={Updatepassword}>Submit</button>
                     </div>
                     
                
     
 </div>
  </div>
   </div>
    </div>
 

    </div>


   <Footer />

    </div>
  );
};
export default Resetpassword;