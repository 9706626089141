import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername } from '../Frontapi.js'; 
   
import {Getuserimage} from '../GetDynamicImages.js';


import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';

const Editprofile = () => {
 
  

const [profilepicture, setProfilepicture] = useState({})

const [profilepicturereqerror, setprofilepicturereqerror] = useState()


      const handlePictureChange = (e) => {

             const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            var name = e.target.files[0].name;
            var lastDot = name.lastIndexOf('.');
            var fileName = name.substring(0, lastDot);
            var ext = name.substring(lastDot + 1);
            setProfilepicture({
                preview: ''
            })
            if (ext == 'jpg' || ext == 'png' || ext == 'webp' || ext == 'jpeg') {
                setProfilepicture(img)
            } else {
                setprofilepicturereqerror("Invalid Image Format")
             }
        }


        const Getuserprofile = async (e) => {
  
           
           

            var postdata = {
                username: Amigousername, userid:AmigouserId
            };
       
                const response = await fetch(Url + "get-user-profile", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                           
                                                        (async function() {
                                                             var userdata=data.data

 
                             setProfilepicture({preview: await Getuserimage(userdata.profilepicture) }  )
                           
                             
                        })()
                        }  
                    })
              
        };



 

        const Updateuserprofilepicture = async (e) => {

            var validationerror=0;

            if(typeof profilepicture.data  == 'undefined' || profilepicture.data == ''){
                setprofilepicturereqerror(requiredErrormessage)
                validationerror=1
            }
 
      if(validationerror == 0){ 

          let formData = new FormData()
                formData.append('profilepicture', profilepicture.data)
                formData.append('username', Amigousername)
                formData.append('userid', AmigouserId)

 
        
            const response = await fetch(Url + "update-user-profile-picture", {
                    method: "POST",
                     
                    body:formData
                    
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)
 
 
                     } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
         


}




        }
      


  useEffect(() => {
     Getuserprofile()
  //   Redirectlogin();
  }, []);
  return (
    <div id="app">


 <input className="form-control" onChange={handlePictureChange}  type="file" name="profilepicture" placeholder="Email"      
                     required/>
                  <br/>
                  { (profilepicture.preview !='') ? <img alt="Profile" className="img-responsive w-100px" src={profilepicture.preview} />
                  : '' }

    <p className="req-error-message">{profilepicturereqerror}</p>



   <a className="login-button" onClick={Updateuserprofilepicture}>Updateuserprofilepicture</a>

    </div>


 
  );
};
export default Editprofile;