import React from "react";
import {frontUrl} from './Frontapi.js';

 
const Loginurl = frontUrl +'login';
const Registerurl = frontUrl +'signup';
const Forgotpasswordurl = frontUrl +'forgot-password';

const Myprofileurl = frontUrl +'my-profile';
const Editprofileurl = frontUrl +'edit-profile';
const Changepasswordurl = frontUrl +'change-password';
const ReferUrl = frontUrl +'refer';

const Logouturl = frontUrl +'logout';



const Contactusurl = frontUrl +'contact-us';
const Aboutusurl = frontUrl +'about-us';
const Termsconditionsurl = frontUrl +'terms-conditions';
const Privacypolicyurl = frontUrl +'privacy-policy';
const Faqurl = frontUrl +'faq';
const Blogdetailurl = frontUrl +'blog/';


const Refundpolicyurl = frontUrl +'refund-policy';
const Licenseagreementurl = frontUrl +'license-agreement';




 
export { Faqurl, Loginurl ,Registerurl ,Blogdetailurl,  Forgotpasswordurl, Myprofileurl , Editprofileurl,Changepasswordurl,ReferUrl,Logouturl, Contactusurl,Aboutusurl,Termsconditionsurl ,Privacypolicyurl,Refundpolicyurl,Licenseagreementurl }