
import React, { useState } from "react";
 

import {frontUrl,TriggerLogout,Redirecthome} from '../Frontapi.js';


 
const Logout = () => {

 
 
  

  

  const navigateToHome = () => {
    window.location.href=frontUrl;
    //navigate(baseUrl); // Use the navigate function to go to the /register route when "Register Now" is clicked
  };

   TriggerLogout();
   navigateToHome();
  return (

    <div  > 

     </div>
 
  );
};
export default Logout;