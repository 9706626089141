import React ,{useEffect,useState} from "react";
import {
    Link 
} from "react-router-dom";

import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';



import {Redirectlogin,Url,SiteFavicon,SiteLogo,AmigouserId} from '../Frontapi.js';

import {Loginurl,Registerurl,Myprofileurl, Logouturl} from '../Siteurls.js';

import {Lang} from "../Languages.js"



 import MetaTags from 'react-meta-tags';



   
import  ('./style.css');


const Navbar = ( ) => {
  
 

   

   

  return (

<div>
       <nav className="navbar navbar-expand-lg  static-top" id="navbar">
            <div className="container">
            <a className="navbar-brand col-md-2" href="#">
            <div className="head-logo"  > 
            <img src={SiteLogo} className="img-responsive w-100pct"      />
            </div>
            
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav col-md-12 col-lg-12 col-sm-12  ">
            <li className="nav-item col-md-2">
            <a className="nav-link nav-link-bold active" aria-current="page" href="#">Tools</a>
            </li>
            <li className="nav-item  col-md-2">
            <a className="nav-link nav-link-bold" href="#">Channels</a>
            </li>
            <li className="nav-item  col-md-2">
            <a className="nav-link nav-link-bold "href="#">Blog</a>
            </li>
            <li className="nav-item dropdown col-md-2">
            <a className="nav-link dropdown-toggle nav-link-bold" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Pricing
            </a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li>
            <hr className="dropdown-divider" />
            </li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
            </ul>
            </li>



            {(AmigouserId != '' && AmigouserId != null) ?  

             <a className="f-dropdown-link nav-link nav-link-bold">My Profile

              <ul className="f-prof-dropdown">

              <li  > <Link to={Myprofileurl} > {Lang('My Account')}  </Link>  < /li>
              <li   >  <Link to={Logouturl} > {Lang('Logout')}   </Link> < /li>


              </ul>

            </a> 

             : <li className="nav-item  col-md-4 display-flex">
            <Link className="nav-link login-link mr-10 wd-100px" to={Loginurl} > <i className="fa fa-user"> </i> {Lang('Login')} </Link>
              <Link className="nav-link login-link wd-150px" to={Registerurl} > {Lang('Get Started Now')}</Link>
            </li> }

 


            </ul>
            </div>
            </div>
          </nav>
 


</div>
 
 

  
  );
};
export default Navbar;