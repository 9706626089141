import React from "react";

import {    useLocation,    useNavigate} from "react-router-dom";
 import 'react-notifications/lib/notifications.css';


import {SiteLogo,SiteFavicon} from '../frontend/ImagesIcons.js';


import {NotificationContainer, NotificationManager} from 'react-notifications';

import {Getserviceimage,Getcategoryimage,Getsubcategoryimage,  Getprofileimage ,Getsliderimage,Getblogcategoryimage,Getblogimage} from '../frontend/GetDynamicImages.js';


import {requiredErrormessage,Notifyalert , validEmailRegex,TextSpaceonlyValidation} from '../frontend/Frontapi.js';
 

//import { createBrowserHistory } from 'history'

 



 

const OwnerName = 'Amigoways Technologies'
const CurrentYear = new Date().getFullYear()
const userId=localStorage.getItem('userId')
const userEmail=localStorage.getItem('userEmail')
const userName=localStorage.getItem('userName')


const profilePictureStorage="../assets/images/"



    

const GetbaseUrl_Url = async(param) => { 
if(window.location.origin === 'http://localhost:3002'){
   if(param === 'base_url'){
        return   'http://localhost:3002/admin-portal/' ;
     }
    if(param === 'url'){
         return   'http://localhost:6010/' ;
    } 
     if(param === 'socekturl'){
         return   'http://localhost:6020/' ;
    }
}else if(window.location.origin === 'http://localhost'){


  if(param === 'base_url'){
        return   'http://localhost/admin-portal/' ;
     }
    if(param === 'url'){
         return   'http://localhost:5090/' ;
    } 
     if(param === 'socekturl'){
         return   '' ;
    }

}else{
    if(param === 'base_url'){
         return   'https://www.alpha-verse.com/admin-portal/' ;
    }
    if(param === 'url'){
           return   'https://www.alpha-verse.com/api/?url=' ;
    } 
      if(param === 'socekturl'){
          return   '' ;
    }


}


}

const baseUrl=await GetbaseUrl_Url('base_url')
const Url=await GetbaseUrl_Url('url')
const Socketiourl=await GetbaseUrl_Url('socekturl')

const Redirectdashboard = () => {

 if(localStorage.getItem('userId') !== '' && localStorage.getItem('userId') !== null ){
     window.location.href=baseUrl+'dashboard';
     return false;
   }
}
const Redirectlogin = () => {
   if(localStorage.getItem('userId') === '' || localStorage.getItem('userId') === null){
  window.location.href=baseUrl+'login';
   }

}
const redirectPage = (page) => {
  window.location.href=baseUrl+page;

 // browserHistory(baseUrl+page)




}

 
const UrlSplitter = (params) => {
         const location = useLocation();
            var url =location.pathname;
            var urls=url.split('/')
            return urls[params]
}


const TriggerLogout = (data) => {

localStorage.removeItem('userId');
       
}

 

const GetProfilePicture = async(param) => {   

if(typeof userId != 'undefined' && userId!=null){ 

    var postdata={id:userId}
var result =  await  fetch(Url + "get-profile", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({postdata}),
    }).then(async (response) => response.json())
    .then(async  (data) => {
         if (data.success &&  data.data !== null) {
            var userdata = data.data;
               return   userdata;


         
        }  
    })
  try {
    if(param === 'name'){
        return result.name;
    }else{
        result=  result.profilepicture    ;
    return result;
}
   
   
    } catch (err) {
   return null;
  }
}
}
const ProfilePictureOutput= await Getprofileimage(await GetProfilePicture('profilepicture')) 
const CurrentUserName=await GetProfilePicture('name')

 

const ModalPopupDelete = (data,type) => {
  
  return (
     <div className="modal fade in" id="myModal" role="dialog">
    <div className="modal-dialog">
    
       <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <h4 className="modal-title">Modal Header</h4>
        </div>
        <div className="modal-body">
          <p>Some text in the modal.</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>
    )
}

const CommonNotify = (data,type) => {
  
  if(type === 'success') {
    // NotificationManager.success(data);
  }
   if(type === 'error') {
   //  NotificationManager.error(data);
  }


   
 

  return (
    <div>   
    <div id="alert" className={type === 'success' ? 'alert alert-success' : 'alert alert-danger'} >{data} </div>
    </div>
    )
 
}



const UpdateNotify = (message,type) => {

     if( type  == 'error'){
         NotificationManager.error(message);
    }else{
          NotificationManager.success(message);
         
    }



  
}



const DeleteNotify = (message) => {

     if(typeof message !='undefined'){
         NotificationManager.error(message);
    }else{
          NotificationManager.success('Deleted successfully');
          setTimeout(function(){
                  //window.location.reload()
          },500)
    }



  
}


const checkEmptyUndefined=(data)=>{

if(typeof data === 'undefined' || data === ''){
  return false
}
return true

} 




const CheckAdminuserExists = async() => {   


var postdata={  userid:userId , useremail : userEmail, username:userName }
var result =  await  fetch(Url + "check-a-user", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {
    
    if(!data.success){
        TriggerLogout(); 
    }
         


         
         
    })

//
}

 



const checkpermissionredirect = async(value) =>{

 
 }


export {Getserviceimage,Getcategoryimage,Getsubcategoryimage,  Getsliderimage,Getblogcategoryimage,Getblogimage, TextSpaceonlyValidation,Notifyalert,requiredErrormessage,checkpermissionredirect,userEmail,userName , Socketiourl,UpdateNotify,DeleteNotify,redirectPage,checkEmptyUndefined,CurrentUserName,profilePictureStorage, SiteLogo,SiteFavicon,userId,Url ,baseUrl,Redirectlogin ,Redirectdashboard,CommonNotify,TriggerLogout,validEmailRegex,OwnerName,CurrentYear,UrlSplitter,ModalPopupDelete,ProfilePictureOutput};