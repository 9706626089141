import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {TextSpaceonlyValidation, MobileNumberValidation,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername , validEmailRegex } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';

const Aboutus = () => {
 

 
  return (
    <div id="app">

    <Header pageInfo="Aboutus" />

    <Navbar />


        <h1 className="login-title">About us</h1>
  

 
 
 
    <Footer />


    </div>


 
  );
};
export default Aboutus;