import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined , Notifyalert,TextSpaceonlyValidation,requiredErrormessage} from './../Api.js';
 
 import {    
    Allowsettings_edit
} from './../Permissions.js';


const Smtp = () => {
 

  

  const [smtphost, setSmtphost] = useState("");
  const [smtpusername, setSmtpusername] = useState("");
  const [smtppassword, setSmtppassword] = useState("");
  const [smtpport, setSmtpport] = useState(""); 
           const [submitLoading, setsubmitLoading] = useState(false);


const[smtphostreqerror,setsmtphostreqerror]=useState("");
const[smtpusernamereqerror,setsmtpusernamereqerror]=useState("");
const[smtppasswordreqerror,setsmtppasswordreqerror]=useState("");
const[smtpportreqerror,setsmtpportreqerror]=useState("");



const handleUserInput = (e) => {
     var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'smtphost':

              setSmtphost(value);
              setsmtphostreqerror("");
             break;
        case 'smtpusername':
            setSmtpusername(value);
                          setsmtpusernamereqerror("");

            break;
       case 'smtppassword':
           setSmtppassword(value );
                         setsmtppasswordreqerror("");

            break;
         case 'smtpport':
             setSmtpport(value );
                           setsmtpportreqerror("");

            break;
             default :
              break;
    }


 }



 const Getsettings = async (e) => {
 
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setSmtphost(settingsdata.smtphost );
             setSmtpusername(settingsdata.smtpusername );
             setSmtppassword(settingsdata.smtppassword );
             setSmtpport(settingsdata.smtpport );
           

            
         
          } else{
              //   
          }

           
       })


 

 } 

 
  
  const Updatesmtpsettings = async (e) => {

var validationerror = 0;
setsmtphostreqerror("");
setsmtpusernamereqerror("");

setsmtppasswordreqerror("");
setsmtpportreqerror("");

    
     if(checkEmptyUndefined(smtphost) == false){

        setsmtphostreqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(smtpusername) == false){

        setsmtpusernamereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(smtppassword) == false){

        setsmtppasswordreqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(smtpport) == false){

        setsmtpportreqerror(requiredErrormessage)
        validationerror=1;
    }
  
  if(validationerror == 0){ 
      var postdata={smtphost:smtphost,smtpusername:smtpusername,smtppassword:smtppassword,smtpport:smtpport   };
 
 
      const response = await fetch(Url+"update-smtp-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                     Notifyalert('success', data.message)
          } else{
                  Notifyalert('error', data.message)
          }

           
       })

     }
     
  };


   

    useEffect(() => {
 Getsettings();
  }, []);

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="companyname">Host<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="smtphost" placeholder="Host" value={smtphost} onChange={(e) => handleUserInput(e)} required/>
                                    <p className="req-error-message">{smtphostreqerror}</p>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="address">Username<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="smtpusername" placeholder="Username" value={smtpusername} onChange={(e) => handleUserInput(e)} required/>
                                     <p className="req-error-message">{smtpusernamereqerror}</p>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Password<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="password" name="smtppassword" placeholder="Password" value={smtppassword} onChange={(e) => handleUserInput(e)} required/>
                                     <p className="req-error-message">{smtppasswordreqerror}</p>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Port<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="smtpport" placeholder="Port" value={smtpport} onChange={(e) => handleUserInput(e)} required/>
                                     <p className="req-error-message">{smtpportreqerror}</p>
                                </div>

                                
                                
                                  <div className="col-sm-12 d-flex justify-content-end">
                                 {(Allowsettings_edit == 1) ? 
                                  <a   className="btn btn-primary me-1 mb-1" onClick={Updatesmtpsettings}>Submit</a> : ''}
                               </div>
                                 
                                  


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Smtp;