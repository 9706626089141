import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined,DeleteNotify, Notifyalert,requiredErrormessage} from './../Api.js';
 
  import {    
    Allowsettings_edit
} from './../Permissions.js';


const Seo = () => {
 
 

  const [meta_title, setMetatitle] = useState("");
  const [meta_keywords, setMetakeywords] = useState("");
  const [meta_description, setMetadescription] = useState("");
 
 const [metatitlereqerror, setmetatitlereqerror]= useState("");
 const [metakeywordsreqerror, setmetakeywordsreqerror]= useState("");
 const [metadescriptionreqerror, setmetadescriptionreqerror]= useState("");


const handleUserInput = (e) => {
     var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'meta_title':
            setmetatitlereqerror('')
              setMetatitle(value);
             break;
        case 'meta_keywords':
            setmetakeywordsreqerror('')
            setMetakeywords(value);
            break;
       case 'meta_description':
        setmetadescriptionreqerror('')
           setMetadescription(value );
            break;
          
             default :
              break;
    }


 }
   
 const Getsettings = async (e) => {
  
 
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setMetatitle(settingsdata.meta_title );
             setMetakeywords(settingsdata.meta_keywords );
             setMetadescription(settingsdata.meta_description );
              
           

            
         
          } else{
              //   
          }

           
       })



 
 } 

  
  const Updateseosettings = async (e) => {
    var validationerror = 0;

setmetatitlereqerror('')
setmetakeywordsreqerror('')
setmetadescriptionreqerror('')

 if(checkEmptyUndefined(meta_title) == false){

        setmetatitlereqerror(requiredErrormessage)
        validationerror=1;
    }
     if(checkEmptyUndefined(meta_keywords) == false){

        setmetakeywordsreqerror(requiredErrormessage)
        validationerror=1;
    }
     if(checkEmptyUndefined(meta_description) == false){

        setmetadescriptionreqerror(requiredErrormessage)
        validationerror=1;
    }

  if(validationerror == 0){ 


      var postdata={meta_title:meta_title,meta_keywords:meta_keywords,meta_description:meta_description  };
 
       const response = await fetch(Url+"update-seo-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                            Notifyalert('success',data.message)

           } else{
                                                Notifyalert('error',data.message)
  
          }

           
       })

     }

     
  };


 
 useEffect(() => {
 Getsettings();
  }, []); 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                 

                                <div className="col-md-4">
                                    <label htmlFor="address">Meta Title<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <textarea className="form-control" type="text" name="meta_title" placeholder="Meta Title" value={meta_title} onChange={(e) => handleUserInput(e)} required></textarea>

                                    <p className="req-error-message">{metatitlereqerror}</p>

                                 </div>


                                 <div className="col-md-4">
                                    <label htmlFor="address">Meta Keywords<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <textarea className="form-control" type="text" name="meta_keywords" placeholder="Meta Keywords" value={meta_keywords} onChange={(e) => handleUserInput(e)} required></textarea>
                                   <p className="req-error-message">{metakeywordsreqerror}</p>
                                 </div>

                                <div className="col-md-4">
                                    <label htmlFor="address">Meta Description<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <textarea className="form-control" type="text" name="meta_description" placeholder="Meta Description" value={meta_description} onChange={(e) => handleUserInput(e)} required></textarea>
                                          <p className="req-error-message">{metadescriptionreqerror}</p>

                                 </div>
                              
                               
                                 
                                  <div className="col-sm-12 d-flex justify-content-end">
                                   {(Allowsettings_edit == 1) ? 
                                  <a type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateseosettings}>Submit</a> : ''}
                               </div>
                              

                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Seo;