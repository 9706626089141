import React from "react";
import {AssetsUrlPath} from './GetDynamicImages.js';

 
 const languageId=localStorage.getItem('languageId');


  const Lang= (word)=>{

 
 try{

 //  fetch('assets/65e16d2488d4e0b94c12d42c.json').then((resp) => resp.text()).then(function(data) {
   //     console.log(data)  ;
  //  });


 // var data = JSON.parse('{"Hello":"Hellos","Home":"Homes"}')
 
 // console.log(data)

return word;
 	 
}catch(error){
	//console.log(error)
}
  

 }

//await getLanguagewords()
 
export {  Lang    }