import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import { requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Sidebar from '../includes/Sidebar';


 import Footer from '../includes/Footer';

  import {Lang} from "../Languages.js"

const Changepassword = () => {
 


const [currentpassword, setCurrentpassword] = useState("");
const [newpassword, setNewpassword] = useState("");
const [confirmpassword, setConfirmpassword] = useState("");

 
 

const [currentpasswordreqerror, setcurrentpasswordreqerror] = useState("");
const [newpasswordreqerror, setnewpasswordreqerror] = useState("");
const [confirmpasswordreqerror, setconfirmpasswordreqerror] = useState("");

  const [btndisabled, setbtndisabled] = useState('btn-disabled');



  const handleUserInput = async(e) => {
     setbtndisabled('')


            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
             case 'currentpassword':
             setCurrentpassword(value);
             setcurrentpasswordreqerror('');
             break;

              case 'newpassword':
             setNewpassword(value);
             setnewpasswordreqerror('');
             break;

              case 'confirmpassword':
             setConfirmpassword(value);
             setconfirmpasswordreqerror('');
             break;

          
            }



 
if(currentpassword == ''  ||  newpassword == '' || confirmpassword == '' ){
  setbtndisabled('btn-disabled')

}
 

    }



  
 const Updateuserprofile = async (e) => {




  var validationerror = 0;
  setcurrentpasswordreqerror('')
  setnewpasswordreqerror('')
  setconfirmpasswordreqerror('')

    

   if(currentpassword == ''){
    setcurrentpasswordreqerror(requiredErrormessage)
    validationerror=1;
  }
    


  if(newpassword == ''){
    setnewpasswordreqerror(requiredErrormessage)
    validationerror=1;
  }

    if(confirmpassword == ''){
    setconfirmpasswordreqerror(requiredErrormessage)
    validationerror=1;
  }
 
 
   if(newpassword !=  confirmpassword){

     setconfirmpasswordreqerror("Password and confirm password not matched")
    validationerror=1;
  } 
    

      if(validationerror == 0){ 
         var postdata = {
            currentpassword:currentpassword,
            newpassword: newpassword,
             username:Amigousername,
             userid:AmigouserId
         };
        
            const response = await fetch(Url + "change-user-password", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)
                        setCurrentpassword('');
                        setNewpassword('')
                        setConfirmpassword('')
 
                     } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
         


}




        }
      

  useEffect(() => {
   //   Redirectlogin();
  }, []);
  return (
    <div id="app">

 <Header pageInfo="Myprofile" />

 <Navbar />


   <div className="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 row">


   
   <Sidebar />


   <div className="col-md-9 col-lg-9 col-xl-10 col-sm-9 col-12" >

   <div className="account-section-right" >
   






    <div className="account-container">
   <div className="card bg-site-gradient  "  >
   <div className="card-body p-5 text-center">
      <div className=" mb-5 ">
       <h1 className="site-head-main-title mb-5 bottom-shadow">{Lang('Change password')}  </h1>


   

 

  <div className="form-outline form-white mb-4">
 <label className="site-form-label">{Lang('Password')}</label>
    <input autoComplete="off"   className="site-form-input mb-10 MustEnterField"   type="password" name="currentpassword" placeholder="Current Password*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{currentpasswordreqerror}</p>

 </div>

     
  <div className="form-outline form-white mb-4">
     <label className="site-form-label">{Lang('New Password')}</label>
    <input autoComplete="off"   className="site-form-input mb-10 MustEnterField"   type="password" name="newpassword" placeholder="New Password*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{newpasswordreqerror}</p>
 </div>

  <div className="form-outline form-white mb-4">
    <label className="site-form-label">{Lang('Confirm Password')}</label>
     <input autoComplete="off"   className="site-form-input mb-10 MustEnterField"   type="password" name="confirmpassword" placeholder="Confirm password*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{confirmpasswordreqerror}</p>

 </div>

                    

                       <button className={ "amigo-btn bg-prim "+btndisabled}    onClick={Updateuserprofile}>Update</button>
                  



      
   </div>
   </div>
   </div>
   









   </div>





   </div>


   </div>


 

   </div>

   
    
      

 
 

 </div>


 
  );
};
export default Changepassword;