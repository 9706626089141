import React from "react";
import {ImagesUrlPath} from './GetDynamicImages.js';


 

const GetImage=async(imagepath)=>{

 return ImagesUrlPath + imagepath;

}
 

const SiteLogo=await GetImage('logo.png')
 const SiteFavicon=await GetImage('favicon.png')


const FacebookImg = await GetImage('channels/facebook.png')
const GoogleImg = await GetImage('channels/google.png')

const InstagramImg = await GetImage('channels/instragram.png')
const LinkedinImg = await GetImage('channels/linkedin.png')
const PinterestImg = await GetImage('channels/pinterest.png')
const ThreadsImg = await GetImage('channels/threads.png')
const TiktokImg = await GetImage('channels/tiktok.png')
const TwitterImg = await GetImage('channels/twitter.png')
const YoutubeImg = await GetImage('channels/youtube.png')
const MastodonImg = await GetImage('channels/mastodon.png')



 
export {SiteLogo,   SiteFavicon , FacebookImg ,GoogleImg,InstagramImg,  LinkedinImg,  PinterestImg ,ThreadsImg,TiktokImg,TwitterImg,YoutubeImg,MastodonImg }

  