import React, { useState, useEffect } from "react";
 import { Link, useNavigate } from "react-router-dom";


import {Sitename,TextSpaceonlyValidation, requiredErrormessage, AmigouserId, Amigousertype, Redirecthome,checkRedirectAfterLogin,RemoveNotificationAfterName, SetNotificationAfterName,SiteLogo, frontUrl,Url,Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined,Notifyalert} from '../Frontapi.js';

import {Loginurl} from '../Siteurls.js';

import {Lang} from "../Languages.js"


import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';

const Register = () => {

 

const navigate = useNavigate();
 
const [fullname, setFullname] = useState("");
const [email, setEmail] = useState("");
const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
const [confirmpassword, setConfirmpassword] = useState("");

 

const [fullnamereqerror, setfullnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");

const [usernamereqerror, setusernamereqerror] = useState("");
const [passwordreqerror, setpasswordreqerror] = useState("");
const [confirmpasswordreqerror, setconfirmpasswordreqerror] = useState("");

const [emailexistserror, setemailexistserror] = useState("");
const [usernameexistserror, setusernameexistserror] = useState("");

const [emailexistsvalidation , setemailexistsvalidation] = useState("0");
const [usernameexistsvalidation , setusernameexistsvalidation] = useState("0");


const [emailvaliderror, setemailvaliderror] = useState("");


 const [btndisabled, setbtndisabled] = useState('btn-disabled');


const handleUserInput = (e) => {

  

  if(e.keyCode === 13){
             signUp(e);
         }

     var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'fullname':
            ( TextSpaceonlyValidation(value) == true ) ?  setFullname(value) :    setfullnamereqerror('');
            
             break;

        case 'email':
            setEmail(value);
             setemailreqerror('');
             checkEmailExists()
             break;
              case 'username':
            setUsername(value)
            setusernamereqerror('');
            checkUsernameExists()
             break;

              case 'password':
            setPassword(value)
            setpasswordreqerror('');
             break;

        case 'confirmpassword':
            setConfirmpassword(value)
            setconfirmpasswordreqerror('');
             break;
    }


      if(fullname == '' || email == ''  || username == '' || confirmpassword == '' || password == ''){
           setbtndisabled('btn-disabled') 
     
    }else{
       setbtndisabled('') 
    }


 }

const checkEmailExists = async (e) => {
    setemailexistserror('')
    setemailexistsvalidation(0)
      var postdata = {   email: email    };
        
            const response = await fetch(Url + "check-email-exists", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        
                        setemailexistserror(data.message)
                        setemailexistsvalidation(1)

                      
                    } else {
                         
                      
                    }
                })

}


const checkUsernameExists = async (e) => {
    setusernameexistserror('')
    setusernameexistsvalidation(0)
      var postdata = {   username: username    };
        
            const response = await fetch(Url + "check-username-exists", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        
                        setusernameexistserror(data.message)
                        setusernameexistsvalidation(1)

                      
                    } else {
                         
                      
                    }
                })

}



const signUp = async (e) => {
  
  var validationerror = 0;
  setfullnamereqerror('')
  setemailreqerror('')
  setusernamereqerror('')
   setpasswordreqerror('')
   setconfirmpasswordreqerror('')

 checkEmailExists()
 checkUsernameExists()

   if(fullname == ''){
    setfullnamereqerror("requiredErrormessage")
    validationerror=1;
  }
   if(email == ''){
    setemailreqerror("requiredErrormessage")
    validationerror=1;
  }
   if(!validEmailRegex.test(email) && email!= ''){
        setemailvaliderror('Invalid Email')
          validationerror=1;

     }


  if(username == ''){
    setusernamereqerror("requiredErrormessage")
    validationerror=1;
  }
    if(password == ''){
    setpasswordreqerror("requiredErrormessage")
    validationerror=1;
  }
  if(confirmpassword == ''){
    setconfirmpasswordreqerror("requiredErrormessage")
    validationerror=1;
  }
  if(password != confirmpassword){
    setconfirmpasswordreqerror("Password and confirm password not matched")
    validationerror=1;
  }
  if(emailexistsvalidation == 1 || usernameexistsvalidation == 1){
     validationerror=1;
  }

      if(validationerror == 0){ 
         var postdata = {
            fullname:fullname,
            email: email,
            username:username,
            password: password
        };
        
            const response = await fetch(Url + "register-user", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)


                        var userdata = data.data;
                        localStorage.setItem('AmigouserId', userdata._id)
                       localStorage.setItem('Amigousername', userdata.username)

                        
                         Redirecthome();
                    } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
                    
        


}
};
 
  useEffect(() => {
     
     Redirecthome();
  }, []);


  return (



    <div id="app">

    <Header pageInfo="Register" />

    <Navbar />



    <div className="main-section"> 


    <div className="login-container"> 

          <div className="card bg-site-gradient  "  >
          <div className="card-body p-5 text-center">

            <div className=" mb-5 ">

              <h1 className="site-head-main-title ">{Lang('Register with')} {Sitename}</h1>
              <p className="  mb-5 ">{Lang('Please enter required data')}!</p>

              <div className="form-outline form-white mb-4">
                   <input autoComplete="off"  type="text" name="fullname" className="site-form-input login-input   MustEnterField"   placeholder="Full name*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{fullnamereqerror}</p>
               </div>


                <div className="form-outline form-white mb-4">

 <input autoComplete="off"  type="text" name="email" className="site-form-input login-input   MustEnterField"   placeholder="Email*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{emailreqerror}</p>
    <p className="req-error-message">{emailexistserror}</p>
    <p className="req-error-message">{emailvaliderror}</p>
                </div>
 <div className="form-outline form-white mb-4">
                
     <input autoComplete="off"  type="text" name="username" className="site-form-input login-input   MustEnterField"   placeholder="Username*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{usernamereqerror}</p>
    <p className="req-error-message">{usernameexistserror}</p>


                </div>
   <div className="form-outline form-white mb-4">
                
 <input autoComplete="off"   className="site-form-input login-input mb-10 MustEnterField"   type="password" name="password" placeholder="Password*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{passwordreqerror}</p>
                </div>      

                      <div className="form-outline form-white mb-4">
               
     <input autoComplete="off"   className="site-form-input login-input mb-10 MustEnterField"   type="password" name="confirmpassword" placeholder="Confirm password*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{confirmpasswordreqerror}</p>


               </div>

 
              <button className={ "amigo-btn bg-prim "+btndisabled}     onClick={signUp} >{Lang('Sign Up')}</button>

            

            </div>

            <div>
              <p  >{Lang('Already have an account')}? <Link to={Loginurl} className=" -50 fw-bold">{Lang('Login')}</Link>
              </p>
            </div>

        
      </div>
    </div>
 
 </div>

 
</div>


 




 
    <Footer />


    </div>


 

 
 
  );
};
export default Register;