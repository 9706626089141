import React, { useState, useEffect } from "react";

  import { Link } from 'react-router-dom';


import {baseUrl,Url,CommonNotify,ProfilePictureOutput,CurrentUserName} from './../Api.js';




import { Getpostimage,Getuserimage } from '../../frontend/GetDynamicImages.js';

 

const Navbar = () => {

 
const [notifycounts,setnotifycounts]=useState(0)

const [ notificationslist,setnotificationslist]=useState( [])


const Updatenotifyviewed = async()=>{
 var postdata={  };

        const response = await fetch(Url+"update-admin-notifications", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 


               setnotifycounts(data.count)


 
 

              } else{
                 
          }

           
       })

}



const Getcounts = async()=>{
 var postdata={  };

        const response = await fetch(Url+"get-admin-notify-counts", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 


               setnotifycounts(data.count)


 
 

              } else{
                 
          }

           
       })

}


 const Getnotifications =  async () => {
  var postdata={   };


        const response = await fetch(Url+"get-admin-notifications", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 


 var notificationlist=data.data;

              Object.keys(notificationlist).forEach(function(key) {
    (async function() { 

 
if (/[[[a-z]*]]/i.test(notificationlist[key].notifymessage)) {

 // notificationlist[key].notifymessage= notificationlist[key].notifymessage.replace(/[[[a-z]*]]/i , '<a>'+ notificationlist[key].username + '</a>'  )
 }
 


                 

               var      image = await Getuserimage( notificationlist[key].userprofilepicture)    

                              

         notificationlist[key].userprofilepicture=image
 
   
    


 
})()
              })


            setnotificationslist(notificationlist)

                } else{
                 
          }


           
       })


}


   useEffect(() => {

 

   
    Getcounts();
    Getnotifications();

  
    },[notifycounts] );

 

   

  return (



 <nav className="navbar navbar-header navbar-expand navbar-light">

   <a className="sidebar-toggler" href="#"><span className="navbar-toggler-icon"></span></a>

   <button className="btn navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"

      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

   <span className="navbar-toggler-icon"></span>

   </button>

   <div className="collapse navbar-collapse" id="navbarSupportedContent">

      <ul className="navbar-nav d-flex align-items-center navbar-light ms-auto">

         <li className="dropdown nav-icon">

            <a href="#" data-bs-toggle="dropdown" className="nav-link  dropdown-toggle nav-link-lg nav-link-user">

               <div className="d-lg-inline-block">

                <div><i className="fa fa-bell" onClick={Updatenotifyviewed}></i>   { (notifycounts > 0) ?<span className="admin-notify-count">{notifycounts}</span> : '' } </div> 

               </div>

            </a>

            <div className="dropdown-menu dropdown-menu-end dropdown-menu-large">

               <h6 className='py-2 px-4'>Notifications</h6>

               <ul className="list-group rounded-none">

  {notificationslist.map((notification) => (
                  <li className="list-group-item border-0 align-items-start">

                     <div className="avatar bg-success me-3">

                        

                        <img src={notification.userprofilepicture} />

                     </div>

                     <div>

 
                        <p className='text-xs'>

                           {notification.notifymessage}

                        </p>

                     </div>

                  </li>
                   ))}



               </ul>

            </div>

         </li>

         <li className="dropdown nav-icon me-2">

            <a href="#" data-bs-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">

               <div className="d-lg-inline-block">

                  <i className="fa fa-eyemail"></i>

               </div>

            </a>

            <div className="dropdown-menu dropdown-menu-end" >

               <Link className="dropdown-item" to={baseUrl+'my-profile'}><i className="fa fa-user"></i> My Profile

</Link>

                <Link className="dropdown-item" to={baseUrl+'settings'}><i className="fa fa-cog"></i> Settings</Link>

               <Link className="dropdown-item" to={baseUrl+'change-password'}><i className="fa fa-key"></i>Change Password</Link>

               <div className="dropdown-divider"></div>

               <a className="dropdown-item" href="#"><i className="fa fa-sign-out"></i> Logout</a>

            </div>

         </li>

         <li className="dropdown">

            <a href="#" data-bs-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">

               <div className="avatar me-1">

                  <img src={ProfilePictureOutput} alt="" srcSet={ProfilePictureOutput} />

               </div>

               <div className="d-none d-md-block d-lg-inline-block"> {CurrentUserName}</div>

            </a>

            <div className="dropdown-menu dropdown-menu-end">

               <Link className="dropdown-item" to={baseUrl+'my-profile'}><i className="fa fa-user"></i> My Profile

</Link>

               {/* <a className="dropdown-item active" href="#"><i className="fa fa-envelope"></i> Messages</a> */}

               <Link className="dropdown-item" to={baseUrl+'settings'}><i className="fa fa-cog"></i> Settings</Link>

               <Link className="dropdown-item" to={baseUrl+'change-password'}><i className="fa fa-key"></i>Change Password</Link>

               <div className="dropdown-divider"></div>

               <Link className="dropdown-item" href={baseUrl+'logout'}><i className="fa fa-sign-out"></i> Logout</Link>

            </div>

         </li>

      </ul>

   </div>

</nav>

  

  );

};

export default Navbar;