import React ,{useEffect,useState} from "react";
 
import { Link, useNavigate } from "react-router-dom";

 import {Settingsdata,SiteLogo,frontUrl , Url, Notifyalert,requiredErrormessage } from '../Frontapi'
import {Lang} from "../Languages.js"

import {Aboutusurl , Faqurl, Contactusurl, Termsconditionsurl,Privacypolicyurl ,Refundpolicyurl ,Licenseagreementurl } from "../Siteurls.js"


// import  ('../../assets/css/frontend/style.css');


const Footer = ( ) => {
  
   const [email, setEmail] = useState("");
const [emailreqerror, setemailreqerror] = useState("");


 const [btndisabled, setbtndisabled] = useState('btn-disabled');

const facebook = Settingsdata['facebook'];
const linkedin = Settingsdata['linkedin'];
const instagram = Settingsdata['instagram'];
const pinterest = Settingsdata['pinterest'];
const youtube = Settingsdata['youtube'];
const footer_content = Settingsdata['footer_content'];
  const handleUserInput = (e) => {


   var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
             setemailreqerror('');
             break;
       
    }

 
    if(email != ''  ){
       setbtndisabled('') 
    }else{
        setbtndisabled('btn-disabled') 
    }
 }





const Subscribe = async () => {
 

  var validationerror = 0;
  setemailreqerror('')
   if(email == ''){
    setemailreqerror(requiredErrormessage)
    validationerror=1;
  }
   
      if(validationerror == 0){ 
         var postdata = {
            email: email 
        };
        
            const response = await fetch(Url + "subscribe-user", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)


                      

                     } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
         


}
};
 

   

  return (
   <div>
   <div className="footer-subscribe-container bg-site-gradient">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-12 row">
         <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-2 display-none-sm">
            <div> 
               <img src={SiteLogo} className="img-responsive"  style={{"width":"100%"}}   />  


            </div>
         </div>
         <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 pr-sm-0 col-12 mb-15">
            <input onKeyUp={(e) => handleUserInput(e)} autoComplete="off"  type="text" name="email" className="site-form-input no-btr-sm no-bbr-sm subscribe-input mb-25 MustEnterField"   placeholder="Email"  
               />
               <p className="req-error-message">{emailreqerror}</p>
         </div>
         <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 pl-sm-0  col-12">
            <div className="display-flex" > 
               <button className={btndisabled+ " amigo-btn bg-prim subscribe-btn no-btl-sm no-bbl-sm   "}   onClick={Subscribe}    >{Lang('Subscribe')}</button>
            </div>
         </div>
      </div>
   </div>
   <section id="footer">
      <div className="container">
         <div className="row text-center text-xs-center text-sm-left text-md-left">
            <div className="col-xs-12 col-sm-3 col-md-3">
               <h5>Company</h5>
               <ul className="list-unstyled quick-links">
                  <li><Link  to={frontUrl}  > Home</Link></li>
                  <li><Link to={Aboutusurl} > About</Link></li>
                  <li><Link  to={Faqurl}  > FAQ</Link></li>
               </ul>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3">
               <h5>Product</h5>
               <ul className="list-unstyled quick-links">
                  <li><a  > Schedule Tool</a></li>
                  <li><a  > Chat Bot</a></li>
               </ul>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3">
               <h5>Support</h5>
               <ul className="list-unstyled quick-links">
                  <li><Link  to={Contactusurl}  > Contact Us</Link></li>
                  <li><a  > Help Center</a></li>
               </ul>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3">
               <h5>Resources</h5>
               <ul className="list-unstyled quick-links">
                  <li><a  > Blog</a></li>
                  <li><a  > Posts</a></li>
                  <li><a  > FAQ</a></li>
                  <li><a  > Get Started</a></li>
                  <li><a href="https://wwwe.sunlimetech.com" title="Design and developed by"> Imprint</a></li>
               </ul>
            </div>
         </div>
         <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
               <ul className="list-unstyled list-inline social text-center">
                {facebook != '' ? <li className="list-inline-item"><a  href={facebook} target="_blank"  ><i className="fa fa-facebook"></i></a></li>  : ''}  

 
                {instagram != '' ? <li className="list-inline-item"><a  href={instagram} target="_blank"   ><i className="fa fa-instagram"></i></a></li>  : ''} 

                {linkedin != '' ? <li className="list-inline-item"><a   href={linkedin} target="_blank"  ><i className="fa fa-linkedin"></i></a></li>  : ''} 

                {pinterest != '' ? <li className="list-inline-item"><a href={pinterest} target="_blank"    ><i className="fa fa-pinterest"></i></a></li>  : ''} 

                  {youtube != '' ? <li className="list-inline-item"><a href={youtube} target="_blank"  ><i className="fa fa-youtube"></i></a></li>  : ''} 


               
               </ul>
            </div>
         </div>
         <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
               <p className="footer-site-links"> <Link   to={Termsconditionsurl}  > Terms and Conditions </Link> <span className="pipeline"> | </span>  <Link   to={Refundpolicyurl} >Refund Policy </Link> <span className="pipeline"> | </span> <Link   to={Licenseagreementurl}  >License Agreement </Link> <span className="pipeline"> | </span> <Link  to={Privacypolicyurl} >Privacy Policy </Link>  </p>
               <p  className="footer-copy-right"  dangerouslySetInnerHTML={{__html:  footer_content}} /> 
            </div>
         </div>
      </div>
   </section>
</div>
  
  );
};
export default Footer;