import React, { useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';
 import Sidebar from '../includes/Sidebar';


 import Footer from '../includes/Footer';

  import {Lang} from "../Languages.js"


import {FacebookImg ,GoogleImg,InstagramImg,  LinkedinImg,  PinterestImg ,ThreadsImg,TiktokImg,TwitterImg,YoutubeImg,MastodonImg} from "../ImagesIcons.js"





const Billing = () => {
   
      const [planList, setplanList] = useState([]);



     const GetPlans = async (value) => {
         
            
            var postdata = {
                
               
            };
            const response = await fetch(Url + "getplan", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var plandata = data.data;
                        setplanList(plandata);
                        
                    } else {}
                })
        };

   

  useEffect(() => {
    GetPlans();
  }, []);
  return (
<div id="app">
   <Header pageInfo="Billing" />
   <Navbar />
   <div className="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 row">
      <Sidebar />
      <div className="col-md-9 col-lg-9 col-xl-10 col-sm-9 col-12" >
         <div className="account-section-right" >


            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12 row" >
            <div className="col-md-9 col-lg-9 col-xl-9 col-sm-9 col-9" >
               <h1 class="text-center"> Confirm Plan</h1>

                  <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12" >
                 {  planList.map((plan) => (

                  <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-3" >
                     {plan.name}
                  </div>


                  ))  }  
               </div>

               


            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-3" >
               <h2>Summary</h2>

               


            </div>





            </div>
             
            


            
         </div>
      </div>
   </div>
</div>
 
  );
};
export default Billing;