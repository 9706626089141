import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined, Notifyalert,requiredErrormessage} from './../Api.js';

 
 import {    
    Allowsettings_edit
} from './../Permissions.js';

 

const Social = () => {

  
 

  const [facebook, setFacebook] = useState("");
   const [instagram, setInstagram] = useState("");
 const [youtube, setYoutube] = useState("");
const [linkedin, setLinkedin] = useState("");
  const [pinterest, setPinterest] = useState("");
 


const[facebookreqerror,setfacebookreqerror]=useState("");
const[instagramreqerror,setinstagramreqerror]=useState("");
const[youtubereqerror,setyoutubereqerror]=useState("");
const[linkedinreqerror,setlinkedinreqerror]=useState("");
const[pinterestreqerror,setpinterestreqerror]=useState("");



const handleUserInput = (e) => {
     var name = e.target.name;
    var value = e.target.value;

    switch (name) {


        case 'facebook':

              setFacebook(value);
              setfacebookreqerror('')
             break;
         case 'instagram':

              setInstagram(value);
                            setfacebookreqerror('')

             break;
             case 'youtube':

              setYoutube(value);
                            setyoutubereqerror('')

             break;
             case 'linkedin':

              setLinkedin(value);
                            setlinkedinreqerror('')

             break;
              case 'pinterest':

              setPinterest(value);
                            setpinterestreqerror('')

             break;
              default :
              break;
    }


 }





 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setFacebook(settingsdata.facebook );
             setInstagram(settingsdata.instagram );
             setYoutube(settingsdata.youtube );
             setLinkedin(settingsdata.linkedin );
            setPinterest(settingsdata.pinterest );

            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 


  
  const Updatesocialsettings  = async (e) => {
 

 var validationerror = 0;
setfacebookreqerror("");
setinstagramreqerror("");
setyoutubereqerror("");
setlinkedinreqerror("");
setpinterestreqerror("");

if(checkEmptyUndefined(facebook) == false){

        setfacebookreqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(instagram) == false){

        setinstagramreqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(youtube) == false){

        setyoutubereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(linkedin) == false){

        setlinkedinreqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(pinterest) == false){

        setpinterestreqerror(requiredErrormessage)
        validationerror=1;
    }


  if(validationerror == 0){ 

      var postdata={facebook:facebook,instagram:instagram,youtube:youtube,linkedin:linkedin ,pinterest:pinterest  };
 
       const response = await fetch(Url+"update-social-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
           if (data.success) { 
       
                Notifyalert('success',data.message)
          } else{
                                 Notifyalert('error',data.message)

          }

           
       })
 
    }
  };


   
 useEffect(() => {
 Getsettings();
  }, []); 
 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="companyname">Facebook<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="facebook" placeholder="Facebook" value={facebook} onChange={(e) => handleUserInput(e)} required/>
                                    <p className="req-error-message">{facebookreqerror}</p>
                                </div>

                                  <div className="col-md-4">
                                    <label htmlFor="companyname">Instagram<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="instagram" placeholder="Instagram" value={instagram} onChange={(e) => handleUserInput(e)} required/>
                                    <p className="req-error-message">{instagramreqerror}</p>
                                </div>

                                 <div className="col-md-4">
                                    <label htmlFor="companyname">Youtube<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="youtube" placeholder="Youtube" value={youtube} onChange={(e) => handleUserInput(e)} required/>
                                    <p className="req-error-message">{youtubereqerror}</p>
                                </div>

                                 <div className="col-md-4">
                                    <label htmlFor="companyname">Linkedin<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="linkedin" placeholder="Linkedin" value={linkedin} onChange={(e) => handleUserInput(e)} required/>
                                                                        <p className="req-error-message">{linkedinreqerror}</p>

                                </div>

                                 <div className="col-md-4">
                                    <label htmlFor="companyname">Pinterest<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="pinterest" placeholder="Pinterest" value={pinterest} onChange={(e) => handleUserInput(e)} required/>
                                                                        <p className="req-error-message">{pinterestreqerror}</p>

                                </div>

                                  {(Allowsettings_edit == 1) ? 
                                 <div className="col-sm-12 d-flex justify-content-end">
                                  <a   className="btn btn-primary me-1 mb-1" onClick={Updatesocialsettings}>Submit</a>
                               </div> : '' }

                                 


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Social;