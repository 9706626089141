import React, { useState ,useEffect} from "react";
 
 import {
    Link,
    useNavigate
} from "react-router-dom";

 
import {Lang} from "../Languages.js"


import Header from "../includes/Header";
import Navbar from "../includes/Navbar";
import Footer from "../includes/Footer";




 

 



 
const Home = () => {
  const navigate = useNavigate();

 
 

   useEffect(() => {
  //   checkss()
  //   Redirectlogin();
  }, []);


  return (

      <div id="app">
 

      <div>{Lang('Coming soon')}</div>



 
 
   </div>
 
  );
};
export default Home;