import React, { useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';
 import Sidebar from '../includes/Sidebar';


 import Footer from '../includes/Footer';

  import {Lang} from "../Languages.js"


const Myprofile = () => {
 


const [fullname, setFullname] = useState("");
const [username, setUsername] = useState("");
 const [email, setEmail] = useState("");


 
 const [submitLoading, setsubmitLoading] = useState(false);

const [fullnamereqerror, setfullnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");


const [usernamereqerror, setusernamereqerror] = useState("");




const [password, setPassword] = useState("");
const [newpassword, setNewpassword] = useState("");
const [confirm_password, setConfirmpassword] = useState("");
const [passwordreqerror, setpasswordreqerror] = useState("");
const [newpasswordreqerror, setnewpasswordreqerror] = useState("");
const [confirm_passwordreqerror, setconfirmpasswordreqerror] = useState("");


 const [btndisabled, setbtndisabled] = useState('btn-disabled');
 const [cbtndisabled, setcbtndisabled] = useState('btn-disabled');


const handleUserInput = (e) => {



}
const handlePasswordUserInput = (e) => {
      var name = e.target.name;
      var value = e.target.value;
setcbtndisabled('')

      switch (name) {
      case 'password':
      setPassword(value);
      break;

    case 'newpassword':
      setNewpassword(value);
      break;

       case 'confirm_password':
      setConfirmpassword(value);
      break;


      }

setTimeout(function(){

if(password == ''  ||  newpassword == '' || confirm_password == '' ){
  setcbtndisabled('btn-disabled')

}

},1000)



      
   
}


  const Getuserprofile = async (e) => {
  
     
     

   var postdata = {
    username: Amigousername, userid:AmigouserId
   };
    
    const response = await fetch(Url + "get-user-profile", {
      method: "POST",
      headers: {
       "Content-Type": "application/json",
      },
      body: JSON.stringify({
       postdata
      }),
     }).then((response) => response.json())
     .then((data) => {
      if (data.success) {
       var userdata=data.data
        
        setFullname(userdata.fullname)
        setUsername(userdata.username)
        setEmail(userdata.email)
       
      }  
     })
     
  };
   

  useEffect(() => {
  Getuserprofile()
  //   Redirectlogin();
  }, []);
  return (
 <div id="app">

 <Header pageInfo="Myprofile" />

 <Navbar />


   <div className="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 row">


   
   <Sidebar />


   <div className="col-md-9 col-lg-9 col-xl-10 col-sm-9 col-12" >

   <div className="account-section-right" >
   






    <div className="account-container">
   <div className="card bg-site-gradient  "  >
   <div className="card-body p-5 text-center">
      <div className=" mb-5 ">
       <h1 className="site-head-main-title mb-5 bottom-shadow">{Lang('Account')}  </h1>

       <div className="form-outline form-white mb-4">
      <label className="site-form-label">{Lang('Full Name')}</label>
      <input autoComplete="off"  type="text" name="fullname" className="site-form-input MustEnterField"   placeholder="Full name*"  
      onChange={(e) => handleUserInput(e)}
      required/>
      <p className="req-error-message">{fullnamereqerror}</p>
      </div>




      <div className="form-outline form-white mb-4">
      <label className="site-form-label">{Lang('Email Address')}</label>
      <input autoComplete="off"  type="text" name="email" className="site-form-input MustEnterField"   placeholder="Email*"  
      onChange={(e) => handleUserInput(e)}
      required/>
      <p className="req-error-message">{emailreqerror}</p>
      </div>
     
    
      <button className={ "amigo-btn bg-prim "+btndisabled}   >{Lang('Save Changes')}</button>
      </div>


  


      
   </div>
   </div>
   </div>
   









   </div>





   </div>


   </div>


 

   
    
      

 
 

 </div>


 
  );
};
export default Myprofile;