import React, { useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';
 import Sidebar from '../includes/Sidebar';


 import Footer from '../includes/Footer';

  import {Lang} from "../Languages.js"


import {FacebookImg ,GoogleImg,InstagramImg,  LinkedinImg,  PinterestImg ,ThreadsImg,TiktokImg,TwitterImg,YoutubeImg,MastodonImg} from "../ImagesIcons.js"


const Channels = () => {
 

   

  useEffect(() => {
   //   Redirectlogin();
  }, []);
  return (
<div id="app">
   <Header pageInfo="Channels" />
   <Navbar />
   <div className="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 row">
      <Sidebar />
      <div className="col-md-9 col-lg-9 col-xl-10 col-sm-9 col-12" >
         <div className="account-section-right" >
            <div className="card bg-site-gradient  "  >
               <div className="card-body  text-center">
                  <div className=" mb-5 ">
                     <h1 className="site-head-main-title mb-5 bottom-shadow">{Lang('Connect Your Channels')}  </h1>
                     <p className="amigo-site-p">Looking for step-by-step instructions? Visit our Help Center to read our Getting Started guides and learn 
                        about supported channel types.
                     </p>
                  </div>
               </div>
            </div>
            <div className=" card  "  >
            <p>Channel Name </p>


             

            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12 mb-5 bg-site-gradient display-inline-flex" >

            <div className="col-md-9 col-lg-9 col-xl-9 col-sm-9 col-9" >
            <img src={FacebookImg} />

            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-3 pt-20px pb-20px" >
              <button class="amigo-btn bg-prim">Connect</button>
            </div>
            </div>


            <div className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12 mb-5 bg-site-gradient  display-inline-flex" >

            <div className="col-md-9 col-lg-9 col-xl-9 col-sm-9 col-9" >
            <img src={GoogleImg} />

            </div>
            <div className="col-md-3 col-lg-3 col-xl-3 col-sm-3 col-3 pt-20px pb-20px" >
              <button class="amigo-btn bg-prim">Connect</button>
            </div>
            </div>






            </div>
         </div>
      </div>
   </div>
</div>
 
  );
};
export default Channels;