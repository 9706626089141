import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined,Notifyalert} from './../Api.js';
 
  import {    
    Allowsettings_edit
} from './../Permissions.js';


const Paymentgateway = () => {
 

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [paymentmode, setPaymentmode] = useState("");
  const [paymentsandboxapiurl, setPaymentsandboxapiurl] = useState("");
  const [paymentliveapiurl, setPaymentliveapiurl] = useState("");
  const [paymentsandboxusername, setPaymentsandboxusername] = useState("");
  const [paymentsandboxpassword, setPaymentsandboxpassword] = useState("");
  const [paymentliveusername, setPaymentliveusername] = useState("");
  const [paymentlivepassword, setPaymentlivepassword] = useState("");


 
                const [submitLoading, setsubmitLoading] = useState(false);


 const Getsettings = async (e) => {



  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
            setPaymentmode(settingsdata.paymentmode );
            setPaymentsandboxapiurl(settingsdata.paymentsandboxapiurl );
            setPaymentliveapiurl(settingsdata.paymentliveapiurl );

            setPaymentsandboxusername(settingsdata.paymentsandboxusername );
            setPaymentsandboxpassword(settingsdata.paymentsandboxpassword );

            setPaymentliveusername(settingsdata.paymentliveusername );
            setPaymentlivepassword(settingsdata.paymentlivepassword );
           

            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 

 useEffect(() => {
 Getsettings();
  }, []); 
  
  const Updatepaymentsettings = async (e) => {
    e.preventDefault();
    setnotification("");

    setsubmitLoading(true)
      if(checkEmptyUndefined(paymentmode) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    try {

      var postdata={
        paymentmode: paymentmode, 
            paymentsandboxapiurl: paymentsandboxapiurl,
            paymentliveapiurl: paymentliveapiurl,
        paymentsandboxusername: paymentsandboxusername,
         paymentsandboxpassword: paymentsandboxpassword,
          paymentliveusername: paymentliveusername,
           paymentlivepassword: paymentlivepassword 
             };
 
       const response = await fetch(Url+"update-payment-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
             if (data.success) { 
                            Notifyalert('success',data.message)

           } else{
                                                Notifyalert('error',data.message)
  
          }


        })
 
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'paymentmode':

              setPaymentmode(value);
             break;
        case 'paymentsandboxapiurl':

              setPaymentsandboxapiurl(value);
             break;
        case 'paymentliveapiurl':

              setPaymentliveapiurl(value);
             break;
        case 'paymentsandboxusername':

              setPaymentsandboxusername(value);
             break;
        case 'paymentsandboxpassword':

              setPaymentsandboxpassword(value);
             break;
        case 'paymentliveusername':

              setPaymentliveusername(value);
             break;               
         case 'paymentlivepassword':

              setPaymentlivepassword(value);
             break;    

 

          
             default :
              break;
    }


 }


 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                 

                                <div className="col-md-4">
                                    <label htmlFor="address">Payment mode<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">

                                 <select className="form-control"  name="paymentmode" onChange={(e) => handleUserInput(e)} >
                                 <option value="">Choose</option>
                                 <option value="sandbox"  selected={paymentmode == 'sandbox' ? 'selected' : '' } >Sandbox</option>
                                 <option value="live" selected={paymentmode == 'live' ? 'selected' : '' }>Live</option>

                                 </select>
                                    
                                 </div>


                                <div className="col-md-4">
                                    <label htmlFor="paymentsandboxapiurl">Api Sandbox Url</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentsandboxapiurl" placeholder="Api Sandbox Url" value={paymentsandboxapiurl} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                                <div className="col-md-4">
                                    <label htmlFor="paymentsandboxusername">Sandbox username / Client Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentsandboxusername" placeholder="Sandbox username / Client Id" value={paymentsandboxusername} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="paymentsandboxpassword">Sandbox password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentsandboxpassword" placeholder="Sandbox password / Secretkey" value={paymentsandboxpassword} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                                





                                <div className="col-md-4">
                                    <label htmlFor="paymentliveapiurl">Api Live Url</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentliveapiurl" placeholder="Api Live Url" value={paymentliveapiurl} onChange={(e) => handleUserInput(e)} required/>
                                </div>



                                <div className="col-md-4">
                                    <label htmlFor="paymentliveusername">Live username / Client Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentliveusername" placeholder="Live username / Client Id" value={paymentliveusername} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="paymentlivepassword">Live password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentlivepassword" placeholder="Live password / Secretkey" value={paymentlivepassword} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                               
                               
                                   {
                               (submitLoading===false) ?  (
                                 <div className="col-sm-12 d-flex justify-content-end">
                                   {(Allowsettings_edit == 1) ? 
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatepaymentsettings}>Submit</button> : ''}
                               </div>
                                ) :'' 
                                }
                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Paymentgateway;