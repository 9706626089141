import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {Sitename,validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirecthome
} from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';
import {Loginurl} from '../Siteurls.js';


 import Footer from '../includes/Footer';
import {Lang} from "../Languages.js"


const Forgotpassword = () => {
 



const [email, setEmail] = useState("");
const [emailreqerror, setemailreqerror] = useState("");
const [emailvaliderror, setemailvaliderror] = useState("");
 const [btndisabled, setbtndisabled] = useState('btn-disabled');



    const handleUserInput = (e) => {
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
            case 'email':
            setEmail(value);
            break;

            }

            if(email != '' ){
            setbtndisabled('') 
            }else{
            setbtndisabled('btn-disabled') 
            }
    }

        const Resetpassword = async (e) => {
  
           
             var validationerror = 0;

             setemailvaliderror('')
             setemailreqerror('')

             if(email == ''){
                  setemailreqerror(requiredErrormessage)
                    validationerror=1;
             }
             if(!validEmailRegex.test(email) && email!= ''){
                setemailvaliderror('Invalid Email')
                  validationerror=1;

             }
           

             if(validationerror == 0){ 

            var postdata = {
                email: email, url:frontUrl
            };
       
                const response = await fetch(Url + "reset-user-password", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                             Notifyalert('success', data.message)
                           //  setEmail('');

                            
                        } else {
                              
                             Notifyalert('error', data.message)
                        }
                    })
              }
        };
      

  useEffect(() => {
     
     Redirecthome();
  }, []);
  return (
  <div id="app">
   <Header pageInfo="Login" />
   <Navbar />
   <div className="main-section">
      <div className="login-container">
         <div className="card bg-site-gradient  "  >
            <div className="card-body p-5 text-center">
               <div className=" mb-5 ">
                  <h1 className="site-head-main-title ">{Lang('Forgot Password')} </h1>
                  <div className="form-group">
                     <input  className="site-form-input login-input  mb-25 MustEnterField"   type="email" name="email" placeholder="Enter your registered email address*" value={email}
                        onChange={(e) => handleUserInput(e)}
                     required/>
                     <p className="req-error-message">{emailreqerror}</p>
                     <p className="req-error-message">{emailvaliderror}</p>
                     <p className="small ">
                        <Link to={Loginurl}     >
                        {Lang('Go back to Login')} </Link>
                     </p>
                  </div>
                  <div className="clearfix">
                     <button className={ "amigo-btn bg-prim "+btndisabled} onClick={Resetpassword}>Send</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <Footer />
</div>
 
  );
};
export default Forgotpassword;