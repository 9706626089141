import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined,Notifyalert} from './../Api.js';
 
  import {    
    Allowsettings_edit
} from './../Permissions.js';


const Footersettings = () => {
 

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [footer_content, setFootercontent] = useState("");
                const [submitLoading, setsubmitLoading] = useState(false);


 const Getsettings = async (e) => {



  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setFootercontent(settingsdata.footer_content );
           
              
           

            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 

 useEffect(() => {
 Getsettings();
  }, []); 
  
  const Updatefootersettings = async (e) => {
    e.preventDefault();
    setnotification("");

    setsubmitLoading(true)
      if(checkEmptyUndefined(footer_content) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    try {

      var postdata={footer_content:footer_content   };
 
       const response = await fetch(Url+"update-footer-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
            if (data.success) { 
                            Notifyalert('success',data.message)

           } else{
                                                Notifyalert('error',data.message)
  
          }


        })
 
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'footer_content':

              setFootercontent(value);
             break;
     
          
             default :
              break;
    }


 }
   

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                 

                                <div className="col-md-4">
                                    <label htmlFor="address">Copyright Content<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <textarea className="form-control" type="text" name="footer_content" placeholder="Copyright Content" value={footer_content} onChange={(e) => handleUserInput(e)} required></textarea>
                                 </div>


                               
                               
                                   {
                               (submitLoading===false) ?  (
                                 <div className="col-sm-12 d-flex justify-content-end">
                                   {(Allowsettings_edit == 1) ? 
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatefootersettings}>Submit</button> : ''}
                               </div>
                                ) :'' 
                                }
                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Footersettings;