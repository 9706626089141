import React, { useState, useEffect } from "react";
 import { Link, useNavigate } from "react-router-dom";


import {Sitename, requiredErrormessage,AmigouserId, Amigousertype, Redirecthome,checkRedirectAfterLogin,RemoveNotificationAfterName, SetNotificationAfterName,SiteLogo,Url,Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined,Notifyalert} from '../Frontapi.js';
import {Registerurl, Forgotpasswordurl} from '../Siteurls.js';

import {Lang} from "../Languages.js"


import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';

const Login = () => {

 

const navigate = useNavigate();
 
 
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
 
 const [submitLoading, setsubmitLoading] = useState(false);

const [usernamereqerror, setusernamereqerror] = useState("");
const [passwordreqerror, setpasswordreqerror] = useState("");


 const [btndisabled, setbtndisabled] = useState('btn-disabled');


const handleUserInput = (e) => {

  

  if(e.keyCode === 13){
             signIn(e);
         }

     var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
             setusernamereqerror('');
             break;
        case 'password':
            setPassword(value)
            setpasswordreqerror('');
             break;
    }



    if(email != '' && password != ''){
       setbtndisabled('') 
    }else{
        setbtndisabled('btn-disabled') 
    }
 }


const signIn = async (e) => {
  
  var validationerror = 0;
  setusernamereqerror('')
   setpasswordreqerror('')
  if(email == ''){
    setusernamereqerror(requiredErrormessage)
    validationerror=1;
  }
  if(password == ''){
    setpasswordreqerror(requiredErrormessage)
    validationerror=1;
  }

      if(validationerror == 0){ 
         var postdata = {
            email: email,
            password: password
        };
        
            const response = await fetch(Url + "login-user", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)


                        var userdata = data.data;
                        localStorage.setItem('AmigouserId', userdata._id)
                        localStorage.setItem('Amigousertype', userdata.usertype)
                        localStorage.setItem('Amigousertype', userdata.usertype)
                        localStorage.setItem('Amigousername', userdata.username)


                         Redirecthome();
                    } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
                  setsubmitLoading(false)
        


}
};
 
  useEffect(() => {
     
     Redirecthome();
  }, []);


  return (



<div id="app">
   <Header pageInfo="Login" />
   <Navbar />
   <div className="main-section">

      <div className="login-container">
         <div className="card bg-site-gradient  "  >
            <div className="card-body p-5 text-center">
               <div className=" mb-5 ">
                  <h1 className="site-head-main-title ">{Lang('Sign in to')} {Sitename}</h1>
                  <p className="  mb-5 ">{Lang('Please enter your login and password')}!</p>
                  <div className="form-outline form-white mb-4">
                     <input autoComplete="off"  type="text" name="email" className="site-form-input login-input   MustEnterField"   placeholder="Email / Username*"  
                        onKeyUp={(e) => handleUserInput(e)}
                     required/>
                     <p className="req-error-message">{usernamereqerror}</p>
                  </div>
                  <div className="form-outline form-white mb-4">
                     <input autoComplete="off"   className="site-form-input login-input   MustEnterField"   type="password" name="password" placeholder="Enter password*"  
                        onKeyUp={(e) => handleUserInput(e)}
                     required/>
                     <p className="req-error-message">{passwordreqerror}</p>
                  </div>
                  <p className="small ">
                     <Link to={Forgotpasswordurl}     >
                     {Lang('Forgot password')}?</Link>
                  </p>
                  <button className={ "amigo-btn bg-prim "+btndisabled}     onClick={signIn} >{Lang('Login')}</button>
               </div>
               <div>
                  <p  >
                     {Lang('Don\'t have an account')}? 
                     <Link to={Registerurl} className=" -50 fw-bold">
                     {Lang('Sign Up')}</Link>
                  </p>
               </div>
            </div>
         </div>
      </div>
      
   </div>
   <Footer />
</div>
 
 
  );
};
export default Login;