import React, { useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';
 import Sidebar from '../includes/Sidebar';


 import Footer from '../includes/Footer';

  import {Lang} from "../Languages.js"


const Refer = () => {
 


const [fullname, setFullname] = useState("");
const [username, setUsername] = useState("");
 const [email, setEmail] = useState("");

 
 const [password, setPassword] = useState("");
 
 const [submitLoading, setsubmitLoading] = useState(false);

const [fullnamereqerror, setfullnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");


const [usernamereqerror, setusernamereqerror] = useState("");
const [passwordreqerror, setpasswordreqerror] = useState("");


 const [btndisabled, setbtndisabled] = useState('btn-disabled');


const handleUserInput = (e) => {


}


  const Getuserprofile = async (e) => {
  
     
     

   var postdata = {
    username: Amigousername, userid:AmigouserId
   };
    
    const response = await fetch(Url + "get-user-profile", {
      method: "POST",
      headers: {
       "Content-Type": "application/json",
      },
      body: JSON.stringify({
       postdata
      }),
     }).then((response) => response.json())
     .then((data) => {
      if (data.success) {
       var userdata=data.data
        
        setFullname(userdata.fullname)
        setUsername(userdata.username)
        setEmail(userdata.email)
       
      }  
     })
     
  };
   

  useEffect(() => {
  Getuserprofile()
  //   Redirectlogin();
  }, []);
  return (
 <div id="app">

 <Header pageInfo="Myprofile" />

 <Navbar />


   <div className="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 row">


   
   <Sidebar />


   <div className="col-md-9 col-lg-9 col-xl-10 col-sm-9 col-12" >

   <div className="account-section-right" >
   






    <div className="account-container">
   <div className="card bg-site-gradient  "  >
   <div className="card-body p-5 text-center">
      <div className=" mb-5 ">
       <h1 className="site-head-main-title mb-5 bottom-shadow">{Lang('Refer a Friend')}  </h1>

    

    <p className="amigo-site-p">Enjoying Buffer? Your friends will too! </p>


    <p className="amigo-site-p">Share Buffer to help them grow an audience with a totally free 
  account. You’ll also support Buffer’s growth, allowing us to continue 
  improving the service.</p>

   <h2 className="site-head-sub-title">How to share </h2>
    <p  className="amigo-site-p">
    1. Find the unique referral link below for your organization. <br/>
    2. Share your link publicly or with someone who’d enjoy using Buffer. <br/>
    3. Your friend signs up for their free Buffer account. <br/>
    4. You and your friend can grow on social and beyond. 
    </p>

    <h2  className="site-head-sub-title">My Organization's unique referral link:</h2>


      <div className="form-outline form-white mb-4">
     
      <input autoComplete="off"  type="text" name="email" className="site-form-input MustEnterField"   placeholder="Email*"  
      onKeyUp={(e) => handleUserInput(e)}
      required/>
      <p className="req-error-message">{emailreqerror}</p>
      </div>
     
    
     


         <div className="col-md-12 col-lg-12 col-sm-12 col-12 col-xl-12 row">
         <div className="col-md-6 col-lg-6 col-sm-6 col-12 col-xl-6">

          <button className={ "amigo-btn bg-prim "}   >{Lang('Copy')}</button>

         </div>
         <div className="col-md-6 col-lg-6 col-sm-6 col-12 col-xl-6">

         <button className={ "amigo-btn bg-prim "}   >{Lang('Share Now')}</button>

         </div>


         </div>


         <p className="amigo-site-p"> Help us improve the Buffer referral experience! Take our quick 
survey.</p>



      </div>


   
  


      
   </div>
   </div>
   </div>
   









   </div>





   </div>


   </div>


 

   
    
      

 
 

 </div>


 
  );
};
export default Refer;