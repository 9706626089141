import React ,{useEffect,useState} from "react";
 
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';



import {Redirectlogin,Url,SiteFavicon} from '../Frontapi.js';
 import MetaTags from 'react-meta-tags';

   
// import  ('../../assets/css/frontend/style.css');


const Header = ({pageInfo} ) => {
  

   const [companyname, setCompanyname] = useState("");
   const [metatitle, setMetatitle] = useState("");
   const [meta_keywords, setMetakeywords] = useState("");
   const [meta_description, setMetadescription] = useState("");



 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setCompanyname(settingsdata.companyname );
            

            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {
      
  }

 } 

const setMetadata =async() => {

if(typeof pageInfo == 'undefined' && typeof title == 'undefined'){
 
}else if(typeof title != 'undefined'){
 
}else{
     

   if(pageInfo == 'Login'){
       setMetatitle('Login')
       setMetakeywords('Login')
       setMetadescription('Login')
   }
     if(pageInfo == 'Register'){
       setMetatitle('Register')
       setMetakeywords('Register')
       setMetadescription('Register')
   }

      if(pageInfo == 'Dashboard'){
       setMetatitle('Dashboard')
       setMetakeywords('Dashboard')
       setMetadescription('Dashboard')
   }


     if(pageInfo == 'Termsconditions'){
       setMetatitle('Terms and Conditions')
       setMetakeywords('Terms and Conditions')
       setMetadescription('Terms and Conditions')
   }

    if(pageInfo == 'Privacypolicy'){
       setMetatitle('Privacy policy')
       setMetakeywords('Privacy policy')
       setMetadescription('Privacy policy')
   }
  
}


 } 

 useEffect(() => {
 Getsettings();

 setMetadata();
  }, []); 

   

  return (

<div>
   <MetaTags>
      <title>{companyname +' - '+ metatitle}</title>

      

      <link rel="shortcut icon" href={SiteFavicon} />
      <meta id="meta-description" name="description" content={meta_description} />
      <meta id="meta-keywords" name="keywords" content={meta_keywords} />

      <meta id="og-title" property="og:title" content={companyname +' - '+ metatitle} />
      <meta id="og-image" property="og:image" content="" />
   </MetaTags>
 


 


 <div className="  toast-notification-color" id="toast-notification"             > 
       
          
        <div id="toast-body-notification" className="toast-body"> 
            Hi! You just scrolled the paragaraph. 
        </div> 
    </div> 


</div>
 
 

  
  );
};
export default Header;