import React, {useEffect, useState } from "react";
 
 import {TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowfaqgroups_view ,
    Allowfaqgroups_add ,
    Allowfaqgroups_edit ,
    Allowfaqgroups_delete 
} from './../../Permissions.js';

 

const Addfaqgroup = () => {

  
const[faqgroupid] = useState(UrlSplitter(4));

const[name, setName] = useState(""); 
  
const[status, setStatus] = useState(1); 



const [namereqerror, setnamereqerror] = useState("");
const [answerreqerror, setanswerreqerror] = useState("");

 


const handleUserInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        
        case 'name':
            setName(value);
            setnamereqerror('');
            break;
       
        
     
         case 'status':
            setStatus(value);
            break;  
        default:
            break;      
    }
 }
 



 
 const Getfaqgroup = async (e) => {
   

     var postdata={_id:faqgroupid};

     const response = await fetch(Url+"getfaqgroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              
              var faqgroupdata=data.data;

                setName(faqgroupdata.name);
                   setStatus(faqgroupdata.status);
         
          } else{
              //   
          }

           
       })

 

 } 



 
  const Updatefaqgroup = async (e) => {

    setnamereqerror('');
 
 
    var validationerror = 0;
 
    if(checkEmptyUndefined(name) == false){
        setnamereqerror(requiredErrormessage)
        validationerror=1;
    }
 


 
     if(validationerror == 0){ 

 
       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;
        
      var postdata={_id:faqgroupid ,name :name, status:statusupdate};
 
      const response = await fetch(Url+"updatefaqgroup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };


    
useEffect(() => {
    Getfaqgroup(faqgroupid);

if(typeof faqgroupid != 'undefined' && faqgroupid !=''){
    checkpermissionredirect(Allowfaqgroups_edit)
}else{
    checkpermissionredirect(Allowfaqgroups_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Faqgroup":"Add Faqgroup"} />

         <Sidebar activePage="Faqs"  activeChildPage="Addcat" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Faq group":"Add Faq group"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Faq group":"Add Faq group"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Name <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                onChange={(e) => handleUserInput(e)}
                required/>
                                    <p className="req-error-message">{namereqerror}</p>

                        </div>
                        
                         
                 
                       
                        
                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>

                       
                        <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Updatefaqgroup}>Submit</a>
                         </div>
                           

                       
                        


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Addfaqgroup;