import React, {useEffect, useState } from "react";
 
 import {Getsliderimage,TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';
   import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowsliders_view ,
    Allowsliders_add ,
    Allowsliders_edit ,
    Allowsliders_delete 
} from './../../Permissions.js';

 

const Addslider = () => {

  
const[sliderid] = useState(UrlSplitter(4));

const[title, setTitle] = useState(""); 
const[content, setContent] = useState(""); 
 const[link, setLink] = useState(""); 

const[status, setStatus] = useState(1); 


const [image, setImage] = useState({preview:''})

const [imagereqerror, setimagereqerror] = useState()


const [titlereqerror, settitlereqerror] = useState("");
const [contentreqerror, setcontentreqerror] = useState("");

 const [linkreqerror, setlinkreqerror] = useState("");



const handleUserInput = (e) => {


     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        
        case 'title':
            setTitle(value);
            settitlereqerror('');
            break;
        case 'content':
            setContent(value);
             setcontentreqerror('');
            break;
         case 'link':
            setLink(value);
             setlinkreqerror('');
            break;
     
         case 'status':
            setStatus(value);
            break;  
        default:
            break;      
    }
 }
 

    const handlePictureChange = (e) => {

             const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            var name = e.target.files[0].name;
            var lastDot = name.lastIndexOf('.');
            var fileName = name.substring(0, lastDot);
            var ext = name.substring(lastDot + 1);
            setImage({
                preview: ''
            })
            if (ext == 'jpg' || ext == 'png' || ext == 'webp' || ext == 'jpeg') {
                setImage(img)
            } else {
                setimagereqerror("Invalid Image Format")
             }
        }




 
 const Getslider = async (e) => {
   

     var postdata={_id:sliderid};

     const response = await fetch(Url+"getslider", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              

    (async function() {
              var sliderdata=data.data;

                    
                setTitle(sliderdata.title);
                setLink(sliderdata.link);
                  setContent(sliderdata.content);
 
 
                             setImage({preview: await Getsliderimage(sliderdata.image) }  )
                           
                             
                        })()




         
          } else{
              //   
          }

           
       })

 

 } 



 
  const Updateslider = async (e) => {

    settitlereqerror('');
     setcontentreqerror('');
     setlinkreqerror('');

 
    var validationerror = 0;
 
    if(checkEmptyUndefined(title) == false){
        settitlereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(content) == false){
        setcontentreqerror(requiredErrormessage)
        validationerror=1;
    } 

    if(checkEmptyUndefined(link) == false){
        setlinkreqerror(requiredErrormessage)
        validationerror=1;
    }   
      
if(sliderid == '' || typeof sliderid == 'undefined'){

if(typeof image.data  == 'undefined' || image.data == ''){
                setimagereqerror(requiredErrormessage)
                validationerror=1
            }
    

}

      


 
     if(validationerror == 0){ 

       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;


            let formData = new FormData()
            formData.append('title', title)
            formData.append('content', content)
            formData.append('link', link)
            formData.append('image', image.data)
            formData.append('status', statusupdate)
            formData.append('_id', sliderid)

 

 
        
  
      const response = await fetch(Url+"updateslider", {
        method: "POST",
       
        body: formData
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) {

                                   Notifyalert('success',data.message)

       
          } else{
                 Notifyalert('error',data.message)
          }

           
       })

     }
  
  };


    
useEffect(() => {
if(typeof sliderid != 'undefined' && sliderid !=''){
    Getslider(sliderid);
}

if(typeof sliderid != 'undefined' && sliderid !=''){
    checkpermissionredirect(Allowsliders_edit)
}else{
    checkpermissionredirect(Allowsliders_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof sliderid !== 'undefined'?"Edit Slider":"Add Slider"} />

         <Sidebar activePage="Sliders"  activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof sliderid !== 'undefined'?"Edit Slider":"Add Slider"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof sliderid !== 'undefined'?"Edit Slider":"Add Slider"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Title <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={title}  type="text" name="title" placeholder="Title"  
                onChange={(e) => handleUserInput(e)}
                required/>
                                    <p className="req-error-message">{titlereqerror}</p>

                        </div>
                        <div className="col-md-4">
                            <label>Content <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             

                                   <ReactQuill theme="snow" value={content}        onChange={setContent} className="textarea-contact-input" />


                    <p className="req-error-message">{contentreqerror}</p>

                        </div>



                         <div className="col-md-4">
                            <label>Link <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="link" placeholder="Link" value={link} 
                onChange={(e) => handleUserInput(e)}
                required/>
                    <p className="req-error-message">{linkreqerror}</p>

                        </div>



                          <div className="col-md-4">
                            <label>Image <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="file" name="image" placeholder="Link"   
                onChange={(e) => handlePictureChange(e)}
                required/>
                 {(image.preview  != '') ? <img  src ={image.preview}  />  : '' }
                    <p className="req-error-message">{imagereqerror}</p>

                        </div>
                         
                         
                 
                       
                        
                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>

                       
                        <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Updateslider}>Submit</a>
                         </div>
                           

                       
                        


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Addslider;