import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {TextSpaceonlyValidation,Siteemail ,Sitephone, Siteaddress, MobileNumberValidation,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername , validEmailRegex } from '../Frontapi.js'; 
  
  import {Lang} from "../Languages.js"

 
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';

const Contactus = () => {
 


const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [mobile, setMobile] = useState("");
const [subject, setSubject] = useState("");

 const [message, setMessage] = useState("");

 const [btndisabled, setbtndisabled] = useState('btn-disabled');


 



const [namereqerror, setnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");
const [mobilereqerror, setmobilereqerror] = useState("");
const [subjectreqerror, setsubjectreqerror] = useState("");
const [messagereqerror, setmessagereqerror] = useState("");

 const [emailvaliderror, setemailvaliderror] = useState("");


  const handleUserInput = async(e) => {
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
             case 'name':
               ( TextSpaceonlyValidation(value) == true ) ?  setName(value) :  setnamereqerror('');

            // setName(value);
             setnamereqerror('');
              break;

              case 'email':
             setEmail(value);
             setemailreqerror('');
             break;

              case 'mobile':
             setMobile(value);
             setmobilereqerror('');
             break;

             case 'subject':
             setSubject(value);
             setsubjectreqerror('');
             break;

             case 'message':
             setMessage(value);
             setmessagereqerror('');
             break;
          
            }


 


        if(name != '' && email != '' && mobile != ''&& subject != ''&& message != ''){
             setbtndisabled('') 
        }else{
             setbtndisabled('btn-disabled') 
        }


    }



  
 const CreateEnquiry = async (e) => {

 


var validationerror = 0;
setnamereqerror()
setemailreqerror()
setmobilereqerror()
setsubjectreqerror()
setmessagereqerror()

    

   if(name == ''){
    setnamereqerror(requiredErrormessage)
    validationerror=1;
  }
    


  if(email == ''){
    setemailreqerror(requiredErrormessage)
    validationerror=1;
  }
 if(!validEmailRegex.test(email) && email!= ''){
  setemailvaliderror('Invalid Email')
  validationerror=1;
 }
    if(mobile == ''){
    setmobilereqerror(requiredErrormessage)
    validationerror=1;
  }
  if(mobile != '' && MobileNumberValidation(mobile) == false ){
setmobilereqerror("Invalid Mobile Number")
    validationerror=1;
  }

   if(subject == ''){
    setsubjectreqerror(requiredErrormessage)
    validationerror=1;
  }
    if(message == ''){
    setmessagereqerror(requiredErrormessage)
    validationerror=1;
  }
 
 
  

      if(validationerror == 0){ 
         var postdata = {
            name:name,
            mobile: mobile,
             email:email,
             subject:subject,
             message:message
         };
        
            const response = await fetch(Url + "create-new-inquiry", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)
 
 
                     } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
         


}




        }
      

 
  return (
   <div id="app">
   <Header pageInfo="Contactus" />
   <Navbar />
   <div className="main-section">
      <div className="main-container">
         <h1 className="site-head-main-title ">{Lang('Contact us')}  </h1>
         <div className="col-md-12 col-lg-12 col-sm-12 col-12 row">



            <div className="col-md-12 col-lg-4 col-sm-4 col-12  ">
                <div className="container">
               <div className="col-md-12 col-lg-12 col-sm-12 col-12 contact-us-info-sec mb-25 ">
                  <div className="contact-us-info-icon">
                     <div className="icon-sec">  <i className="fa fa-mobile"></i> </div>
                  </div>
                  <div className="contact-us-info-label"> {Sitephone}</div>
               </div>
               <div className="col-md-12 col-lg-12 col-sm-12 col-12 contact-us-info-sec mb-25 ">
                  <div className="contact-us-info-icon">
                     <div className="icon-sec"> <i className="fa fa-envelope"></i> </div>
                  </div>
                  <div className="contact-us-info-label"> {Siteemail}</div>
               </div>
               <div className="col-md-12 col-lg-12 col-sm-12 col-12 contact-us-info-sec mb-25">
                  <div className="contact-us-info-icon">
                     <div className="icon-sec"> <i className="fa fa-map-marker"></i>  </div>
                  </div>
                  <div className="contact-us-info-label"> {Siteaddress}</div>
               </div>
                </div>
            </div>



            <div className="col-md-12 col-lg-8 col-sm-8 col-12  ">
            <div className="container">
               <div className="card bg-site-gradient  "  >
                  <div className="card-body text-center">
                     <div className=" mb-5 ">
                        <div className="col-md-12 col-lg-12 col-12  display-md-flex ">
                           <div className="col-md-6 col-lg-6 col-12 mr-5 ">
                              <div className="form-outline form-white mb-4">
                                 <input autoComplete="off"  value={name}  className="site-form-input mb-10 MustEnterField"   type="text" name="name" placeholder="Name*"  
                                    onChange={(e) => handleUserInput(e)}
                                 required/>
                                 <p className="req-error-message">{namereqerror}</p>
                              </div>
                           </div>
                           <div className="col-md-6 col-lg-6 col-12  ">
                              <div className="form-outline form-white mb-4">
                                 <input autoComplete="off"   className="site-form-input mb-10 MustEnterField"   type="text" name="mobile" placeholder="Mobile*"  
                                    onKeyUp={(e) => handleUserInput(e)}
                                 required/>
                                 <p className="req-error-message">{mobilereqerror}</p>
                              </div>
                           </div>
                        </div>
                        <div className="form-outline form-white mb-4">
                           <input autoComplete="off"   className="site-form-input mb-10 MustEnterField"   type="text" name="email" placeholder="Email*"  
                              onKeyUp={(e) => handleUserInput(e)}
                           required/>
                           <p className="req-error-message">{emailreqerror}</p>
                           <p className="req-error-message">{emailvaliderror}</p>
                        </div>
                        <div className="form-outline form-white mb-4">
                           <input autoComplete="off"   className="site-form-input mb-10 MustEnterField"   type="text" name="subject" placeholder="Subject*"  
                              onKeyUp={(e) => handleUserInput(e)}
                           required/>
                           <p className="req-error-message">{subjectreqerror}</p>
                        </div>
                        <div className="form-outline form-white mb-4">
                           <textarea autoComplete="off"   className="site-form-textarea mb-10 MustEnterField" rows="10"   type="text" name="message" placeholder="Message*"  
                              onKeyUp={(e) => handleUserInput(e)}
    required></textarea>
                           <p className="req-error-message">{messagereqerror}</p>
                        </div>
                        <button   className={ "amigo-btn bg-prim "+btndisabled}  onClick={CreateEnquiry}>{Lang('Create Enquiry')}</button>
                     </div>
                  </div>
               </div>
            </div>
</div>

         </div>
      </div>
   </div>
   <Footer />
</div>

 
  );
};
export default Contactus;