import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import {validEmailRegex,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername } from '../Frontapi.js'; 
   
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';


  import Editprofilepicture from './Editprofilepicture';


const Editprofile = () => {
 


const [fullname, setFullname] = useState("");
const [username, setUsername] = useState("");
const [email, setEmail] = useState("");

 
const [fullnamereqerror, setfullnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");
const [usernamereqerror, setusernamereqerror] = useState("");



const [emailexistsvalidation , setemailexistsvalidation] = useState("0");
const [usernameexistsvalidation , setusernameexistsvalidation] = useState("0");


const [emailexistserror, setemailexistserror] = useState("");
const [usernameexistserror, setusernameexistserror] = useState("");


const [emailvaliderror, setemailvaliderror] = useState("");


  const handleUserInput = async(e) => {
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
             case 'fullname':
            setFullname(value);
             setfullnamereqerror('');
             break;

            case 'email':
            setEmail(value);
            setemailreqerror('');
            checkEmailExists()
            break;
            case 'username':
            setUsername(value)
            setusernamereqerror('');
            setusernameexistserror('')
            setusernameexistsvalidation(0)
  
                 
           
            break;

            }
    }




        const Getuserprofile = async (e) => {
  
           
           

            var postdata = {
                username: Amigousername, userid:AmigouserId
            };
       
                const response = await fetch(Url + "get-user-profile", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            var userdata=data.data
                             
                             setFullname(userdata.fullname)
                             setUsername(userdata.username)
                             setEmail(userdata.email)
                            
                        }  
                    })
              
        };




const checkEmailExists = async (e) => {
    setemailexistserror('')
    setemailexistsvalidation(0)
      var postdata = {   email: email    };
        
            const response = await fetch(Url + "check-email-exists", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        
                        setemailexistserror(data.message)
                        setemailexistsvalidation(1)

                      
                    } else {
                         
                      
                    }
                })

}


const checkUsernameExists = async (e) => {


    setusernameexistserror('')
    setusernameexistsvalidation(0)
      var postdata = {   username: username , userid:AmigouserId   };
        
            const response = await fetch(Url + "check-username-exists", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        
                        setusernameexistserror(data.message)
                        setusernameexistsvalidation(1)
                        return 1
                      
                    } else {
                         
                      
                    }
                })

                return response;


}

        const Updateuserprofile = async (e) => {




  var validationerror = 0;
  setfullnamereqerror('')
  setusernamereqerror('')

  await checkUsernameExists()
   

   if(fullname == ''){
    setfullnamereqerror("requiredErrormessage")
    validationerror=1;
  }
    


  if(username == ''){
    setusernamereqerror("requiredErrormessage")
    validationerror=1;
  }
  if(await checkUsernameExists() == 1){
     validationerror=1;
  }
    

      if(validationerror == 0){ 
         var postdata = {
            fullname:fullname,
             username:username,
             userid:AmigouserId
         };
        
            const response = await fetch(Url + "update-user-profile", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)
                       localStorage.setItem('Amigousername', data.data.username)

 
                     } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
         


}




        }
      

  useEffect(() => {
     Getuserprofile()
  //   Redirectlogin();
  }, []);
  return (
    <div id="app">

    <Header pageInfo="Editprofile" />

    <Navbar />


        <h1 className="login-title">My profile</h1>
  


<Editprofilepicture />
                       






    <input autoComplete="off" value={email}  type="text" name="email" className="form-control login-input mb-25 MustEnterField"   placeholder="Email*"  
    onKeyUp={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{emailreqerror}</p>
    <p className="req-error-message">{emailexistserror}</p>
    <p className="req-error-message">{emailvaliderror}</p>


    



              <input autoComplete="off" value={fullname}  type="text" name="fullname" className="form-control login-input mb-25 MustEnterField"   placeholder="Full name*"  
    onChange={(e) => handleUserInput(e)}
    required/>
    <p className="req-error-message">{fullnamereqerror}</p>




    


     <input autoComplete="off"  type="text" value={username}  name="username" className="form-control login-input mb-25 MustEnterField"   placeholder="Username*"  
    onChange={(e) => handleUserInput(e)}
    required/>
        <p className="req-error-message">{usernamereqerror}</p>
    <p className="req-error-message">{usernameexistserror}</p>


                    

                       <a className="login-button" onClick={Updateuserprofile}>Updateuserprofile</a>
                  


 
    <Footer />


    </div>


 
  );
};
export default Editprofile;