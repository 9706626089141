import React from "react";
import {  Routes, Route } from "react-router-dom";


/*
import FrontendLogin from "./frontend/auth/Login";
import FrontendRegister from "./frontend/auth/Register";

import FrontendRegisterTeacher from "./frontend/auth/Registerteacher";

import FrontendResetpassword from "./frontend/auth/Resetpassword";
import FrontendLogout from "./frontend/auth/Logout";
import FrontendAbout from "./frontend/cms/About"; 

import FrontendTermsConditions from "./frontend/cms/Terms"; 

import FrontendRefundPolicy from "./frontend/cms/Refund"; 

import FrontendPrivacyPolicy from "./frontend/cms/Privacy"; 


import FrontendContactUs from "./frontend/cms/Contact"; 
import FrontendProfile from "./frontend/profile/Profile";


*/
 import FrontendHome from "./frontend/pages/Home";

  import FrontendComingsoon from "./frontend/pages/Comingsoon";

 

import FrontendLogin from "./frontend/auth/Login";

import FrontendRegister from "./frontend/auth/Register";
import FrontendForgotpassword from "./frontend/auth/Forgotpassword";
import FrontendResetpassword from "./frontend/auth/Resetpassword";

  

 





 
 import FrontendMyprofile from "./frontend/profile/Myprofile";


 import FrontendEditprofile from "./frontend/profile/Editprofile";

  import FrontendChangepassword from "./frontend/profile/Changepassword";
  import FrontendLogout from "./frontend/profile/Logout";



  import FrontendRefer from "./frontend/user/Refer";
  import FrontendChannels from "./frontend/user/Channels";

  import FrontendBilling from "./frontend/user/Billing";



  import FrontendBlog from "./frontend/pages/Blog";
    import FrontendBlogDetail from "./frontend/pages/BlogDetail";


  import FrontendFaq from "./frontend/cms/Faq";


  import FrontendContactus from "./frontend/cms/Contactus";


    import FrontendPrivacypolicy from "./frontend/cms/Privacypolicy";
    import FrontendAboutus from "./frontend/cms/Aboutus";
    import FrontendTermsConditions from "./frontend/cms/Termsconditions";

    import FrontendRefundPolicy from "./frontend/cms/Refundpolicy";
        import FrontendLicenseagreement from "./frontend/cms/Licenseagreement";





 //Admin


import AdminLogin from "./admin/auth/Login";
import AdminLogout from "./admin/auth/Logout";
import  AdminForgotpassword from "./admin/auth/Forgotpassword";
import  AdminResetpassword from "./admin/auth/Resetpassword";



import AdminSettings from "./admin/profile/Settings";
import AdminChangepassword from "./admin/profile/Changepassword";
import AdminProfile from "./admin/profile/Profile";


import AdminDashboard from "./admin/pages/dashboard";


import AdminadminUserlist from "./admin/pages/adminusers/List";
import AdminadminUseradd from "./admin/pages/adminusers/Add";

import AdminRolelist from "./admin/pages/roles/List";
import AdminRoleadd from "./admin/pages/roles/Add";

import AdminCmslist from "./admin/pages/cms/List";
import AdminCmsadd from "./admin/pages/cms/Add";


import AdminEmailtemplateslist from "./admin/pages/emailtemplates/List";
import AdminEmailtemplatesadd from "./admin/pages/emailtemplates/Add";
  
 
import AdminEnquirieslist from "./admin/pages/enquiries/List";
 
 

 import AdminUserlist from "./admin/pages/users/List";
import AdminUseradd from "./admin/pages/users/Add";
import AdminUseredit from "./admin/pages/users/Add";


 import AdminFaqlist from "./admin/pages/faq/List";
import AdminFaqadd from "./admin/pages/faq/Add";
import AdminFaqedit from "./admin/pages/faq/Add";

 import AdminFaqgrouplist from "./admin/pages/faq/Listgroup";
import AdminFaqgroupadd from "./admin/pages/faq/Addgroup";
import AdminFaqgroupedit from "./admin/pages/faq/Addgroup";



 import AdminSliderlist from "./admin/pages/sliders/List";
import AdminSlideradd from "./admin/pages/sliders/Add";
import AdminSlideredit from "./admin/pages/sliders/Add";


 



function App() { 
  return (

     <div className="  h-screen duration-500">
      
     
        <Routes>

     


      <Route path="/login" element={<FrontendLogin />} />
      <Route path="/signup" element={<FrontendRegister />} />
      <Route path="/forgot-password" element={<FrontendForgotpassword />} />
      <Route path="/reset-password/:id" element={<FrontendResetpassword />} />


    {/*       <Route path="/teacher-register" element={<FrontendRegisterTeacher />} />
           <Route path="/forgot-password" element={<FrontendForgotpassword />} />
          <Route path="/reset-password/:token" element={<FrontendResetpassword />} />
        
               <Route path="/contact-us" element={<FrontendContactUs />} />

         <Route path="/about-us" element={<FrontendAbout />} />
          <Route path="/terms-conditions" element={<FrontendTermsConditions />} />
           <Route path="/privacy-policy" element={<FrontendPrivacyPolicy />} />
            <Route path="/refund-policy" element={<FrontendRefundPolicy />} />

              <Route path="/my-profile/preferences" element={<FrontendProfile />} />
                    


  */ }

              <Route path="/my-profile" element={<FrontendMyprofile />} />
              <Route path="/edit-profile" element={<FrontendEditprofile />} />
              <Route path="/change-password" element={<FrontendChangepassword />} />
                <Route path="/logout" element={<FrontendLogout />} />

               <Route path="/refer" element={<FrontendRefer />} />

              <Route path="/channels" element={<FrontendChannels />} />

                <Route path="/billing" element={<FrontendBilling />} />



               <Route path="/faq" element={<FrontendFaq />} />

                              <Route path="/blog/:id" element={<FrontendBlogDetail />} />

               <Route path="/blog" element={<FrontendBlog />} />



              <Route path="/contact-us" element={<FrontendContactus />} />


              
 


            <Route path="/about-us" element={<FrontendAboutus />} />
            <Route path="/terms-conditions" element={<FrontendTermsConditions />} />
            <Route path="/privacy-policy" element={<FrontendPrivacypolicy />} />
            <Route path="/refund-policy" element={<FrontendRefundPolicy />} />
             <Route path="/license-agreement" element={<FrontendLicenseagreement />} />

            {/* <Route exact path="/" element={<FrontendHome />} />*/}


                        <Route exact path="/" element={<FrontendComingsoon />} />

 
 

 
          {/* Admin */}


          <Route path="/admin-portal/" element={<AdminLogin />} />
         <Route path="/admin-portal/login" element={<AdminLogin />} />
         <Route path="/admin-portal/logout" element={<AdminLogout />} />
     
           <Route path="/admin-portal/forgot-password" element={<AdminForgotpassword />} />
           <Route path="/admin-portal/reset-password/:token" element={<AdminResetpassword />} />


           <Route path="/admin-portal/change-password" element={<AdminChangepassword />} />
           <Route path="/admin-portal/settings" element={<AdminSettings />} />
           <Route path="/admin-portal/my-profile" element={<AdminProfile />} />

           <Route path="/admin-portal/dashboard" element={<AdminDashboard />} />

           <Route path="/admin-portal/adminusers" element={<AdminadminUserlist />} />
            <Route path="/admin-portal/adminusers/add" element={<AdminadminUseradd />} />
             <Route path="/admin-portal/adminusers/edit/:id" element={<AdminadminUseradd />} />
             <Route path="/admin-portal/roles" element={<AdminRolelist />} />
              <Route path="/admin-portal/roles/add" element={<AdminRoleadd />} />
             <Route path="/admin-portal/roles/edit/:id" element={<AdminRoleadd />} />

               <Route path="/admin-portal/cms" element={<AdminCmslist />} />
               <Route path="/admin-portal/cms/edit/:id" element={<AdminCmsadd />} />


               <Route path="/admin-portal/emailtemplates" element={<AdminEmailtemplateslist />} />
               <Route path="/admin-portal/emailtemplates/edit/:id" element={<AdminEmailtemplatesadd />} />


            

 

               <Route path="/admin-portal/users" element={<AdminUserlist />} />
              <Route path="/admin-portal/users/add" element={<AdminUseradd />} />
             <Route path="/admin-portal/users/edit/:id" element={<AdminUseredit />} />

               

              <Route path="/admin-portal/enquiries" element={<AdminEnquirieslist />} />

 
               <Route path="/admin-portal/faq" element={<AdminFaqlist />} />
              <Route path="/admin-portal/faq/add" element={<AdminFaqadd />} />
             <Route path="/admin-portal/faq/edit/:id" element={<AdminFaqedit />} />

                  <Route path="/admin-portal/faqgroup" element={<AdminFaqgrouplist />} />
              <Route path="/admin-portal/faqgroup/add" element={<AdminFaqgroupadd />} />
             <Route path="/admin-portal/faqgroup/edit/:id" element={<AdminFaqgroupedit />} />

               
              < Route path="/admin-portal/sliders" element={<AdminSliderlist />} />
              <Route path="/admin-portal/sliders/add" element={<AdminSlideradd />} />
             <Route path="/admin-portal/sliders/edit/:id" element={<AdminSlideredit />} />

           
 


        </Routes>
       
    </div>
    );
}

export default App;
